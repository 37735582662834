import React from 'react'

const Services:React.FC = () => {
    return (
        <section className="w-full px-[5%] py-16" data-scroll-section>
            <h1 className="text-[32px] md:text-[74px] font-medium">Our Services</h1>
            <p className="text-sm text-gray max-w-[600px]">
                Electrical Solutions offers a comprehensive range of services designed to meet the diverse needs of modern infrastructure.          
            </p>
            <div className="border-t-black-600 border-t-[1px] mt-14 py-14 md:px-10 about-grid">
                <h2 className="text-black-500 text-[32px] font-medium md:text-[40px] leading-none">
                    Mechanical Services
                </h2>
                <div>
                    <p className="text-white-350 text-[16px] mt-6 md:text-[28px]">
                        Our expertise ensures that every project is designed and executed with precision, focusing on performance, energy efficiency, and sustainability. From initial design to implementation, we provide solutions that meet the unique needs of each project, ensuring seamless integration and long-term reliability of mechanical systems.
                    </p>
                    <div className=" flex md:items-center flex-col md:flex-row md:flex-wrap w-full max-w-[900px] gap-10 mt-12 text-gray text-sm italic">
                        <div>Air-Conditioning and Ventilation System</div>
                        <div>Refrigeration, Cold Rooms and Storage System</div>
                        <div>Plumbing System</div>
                        <div>External Water Supply and Drainage reticulation</div>
                        <div>Borehole and water treatment plant</div>
                        <div>Lifts and Escalators</div>
                        <div> Refuse collection and /or Disposal systems.</div>
                    </div>
                </div>
            </div>
            <div className="border-t-black-600 border-t-[1px] mt-14 py-14 md:px-10 about-grid">
                <h2 className="text-black-500 text-[32px] font-medium md:text-[40px] leading-none">
                    Electrical Services
                </h2>
                <div>
                    <p className="text-white-350 text-[16px] mt-6 md:text-[28px]">
                        We specialize in creating innovative electrical systems that enhance the functionality and security of residential, commercial, and industrial spaces. Our team ensures precise design and implementation, providing seamless integration of advanced technologies to meet the specific power and communication needs of each project. 
                    </p>
                    <div className=" flex md:items-center flex-col md:flex-row md:flex-wrap w-full max-w-[900px] gap-10 mt-12 text-gray text-sm italic">
                        <div>Lighting and Power Distribution</div>
                        <div>Telephone Systems</div>
                        <div> Fire detection and Alarm System</div>
                        <div>Closed Circuit Television</div>
                        <div>Installation of Substation from 11kv to 13kv</div>
                        <div>Street lighting and Rural Electrification</div>
                        <div>Public Address and Call Systems</div>
                        <div> Controls and Instrumentations</div>
                        <div>Lightning protection systems.</div>
                    </div>
                </div>
            </div>
            <div className="border-t-black-600 border-t-[1px] mt-14 py-14 md:px-10 about-grid">
                <h2 className="text-black-500 text-[32px] font-medium md:text-[40px] leading-none">
                    Installation Services
                </h2>
                <div>
                    <p className="text-white-350 text-[16px] mt-6 md:text-[28px]">
                        Our team ensures that each project is tailored to meet the specific needs and standards of the building type, delivering reliable, efficient, and high-quality installations that enhance functionality and safety.
                    </p>
                    <div className=" flex md:items-center flex-col md:flex-row md:flex-wrap w-full max-w-[900px] gap-10 mt-12 text-gray text-sm italic">
                        <div>Private residential building and Estates</div>
                        <div>Office building</div>
                        <div> Industrial buildings</div>
                        <div>Commercial building (e.g. banks, hotels, shopping malls, etc)</div>
                        <div>Hospital / Clinics</div>
                        <div>Sports and Recreational center</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services