import {useState, useEffect, useRef} from 'react'
import arrowUp from '../../Assets/Icons/arrow-up.svg'
import arrowDown from '../../Assets/Icons/arrow-down.svg'

type DropDownErrorType = {
    valid: boolean,
    message: string,
}

interface DropDownType{
    title: string,
    defaultPlaceHolder: string,
    error: DropDownErrorType,
    value:string | undefined,
    list: string[],
    save: (value: string) => void
}

const Dropdown:React.FC<DropDownType> = (props) => {
    const dropdownRef = useRef<HTMLDivElement>(null)
    const [value, setValue] = useState<string>(props.defaultPlaceHolder)
    const [open, setOpen] = useState<boolean>(false)

    const handleClick = (value: string) => {
        setValue(value)
        props.save(value)
        setOpen(false)
    }

    useEffect(() => {
        const closeDropdownOnOutsideClick = (e: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
                setOpen(false);
            }
        };
    
        document.addEventListener('click', closeDropdownOnOutsideClick);
    
        return () => {
            document.removeEventListener('click', closeDropdownOnOutsideClick);
        };
    }, []);

    const borderColor = open ? "border-orange" : props.error?.valid ? "border-red-500" : "border-white-375 ";

    return (
        <div className='w-full'>
            <small>{props.error?.message}</small>
            <div className='w-full relative' ref={dropdownRef}>
                <p className='text-sm font-medium mb-1'>{props.title}</p>
                <div onClick={()=>setOpen(!open)} className={"w-full border-[1.5px] rounded-lg p-3 cursor-pointer flex items-center justify-between " + borderColor}>
                    <span className={props.value === "" ? "text-white-495" : ""}>
                        {
                            props.value === '' ? props.defaultPlaceHolder : props.value !== '' ? props.value : value
                        }
                    </span>
                    <img src={open ? arrowUp  : arrowDown } alt="Arrow" />
                </div>
                {open && 
                    <ul className="w-full absolute top-full mt-3 bg-white-600 rounded-lg shadow-xl z-10 bg-white flex flex-col gap-2 border-white-400 border-[1px] p-3">
                        {
                            props.list.map((value, index)=>{
                            return (
                                        <li key={index} onClick={()=>handleClick(value)} className="cursor-pointer">{value}</li>
                                    )
                                }
                            )
                        }
                    </ul>
                }
            </div>
        </div>
    )
}

export default Dropdown