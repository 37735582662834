interface MyObject {
    [key: string]: string;
    home: string;
    services: string;
    projects: string;
    contact: string;
}

export const REVIEWS = [
    {
        name: "Adesolaojo Steven",
        review: "“OMECON Engineering exceeded our expectations with their lighting and power distribution services. Their attention to detail ensured that our office building not only met all safety standards but also had an efficient and aesthetically pleasing lighting setup." 
    },
    {
        name: "Bello Saheed",
        review: "“Working with the company was a truly positive experience. Their team was professional, knowledgeable, and always went the extra mile to ensure everything was done to the highest standards. They made the entire process smooth and stress-free, and we were thrilled with the results. It’s clear they take great pride in their work.”"
    },
    {
        name: "Adeosun Michael",
        review: "“The quality of their work speaks for itself, and their commitment to meeting deadlines and staying within budget was greatly appreciated. We would gladly work with them again.”"
    },
    {
        name: "Senboyejo Adebayo",
        review: "“They took the time to understand our needs and delivered a solution that was both innovative and practical. The quality of their work was exceptional, and their dedication to customer satisfaction was evident throughout the process.”"
    },
    {
        name: "Ajiboye Ademola",
        review: "“The end product was of the highest quality, and the entire process was handled with professionalism and care. We couldn’t be happier with the outcome.”"
    },
    {
        name: "Ologure Gabriel",
        review: "“They were incredibly thorough, from the initial planning stages to the final execution. Their attention to detail and ability to solve problems efficiently made the entire process much easier. The final outcome was a testament to their skill and commitment to quality.”"
    },
    {
        name: "Popoola Kemisola",
        review: "“From our first interaction with omecon, it was clear that they were a team of dedicated professionals. Their thorough approach and commitment to delivering quality results made a significant impact on the success of our project.”"
    },
    {
        name: "Oluwalade Olanrewaju",
        review: "“The level of service provided was exceptional. They tackled our project with enthusiasm and expertise, ensuring that every detail was addressed. Their commitment to delivering top-quality work was clear throughout the project.”"
    },
]

export const IDs:MyObject = {
    home: "home-container",
    services: "services-container",
    projects: "projects-container",
    contact: "contact-container",
    about: "about-container",
    project: "current-project-container"
}

export const VALUES = [
    {
        value: "Excellence",
        description: "We are committed to delivering the highest quality in every project we undertake. Our attention to detail and continuous pursuit of improvement ensure that we exceed client expectations, setting new standards in engineering solutions."
    },
    {
        value: "Innovation",
        description: "We actively seek out and adopt the latest technologies and methodologies to provide innovative solutions. By fostering creativity and resourcefulness, we tailor our services to meet the evolving needs of our clients."
    },
    {
        value: "Customer Experience",
        description: "We prioritize our clients' satisfaction, ensuring that their needs are at the center of everything we do. Through open communication and personalized service, we strive to build lasting relationships based on trust and mutual respect."
    },
    {
        value: "Timeliness",
        description: "We understand the importance of meeting deadlines and completing projects within the agreed-upon timeframe. Our efficient project management processes ensure that we deliver high-quality work on time without compromising standards."
    },
    {
        value: "Integrity",
        description: "We uphold the highest ethical standards in all our dealings. Transparency, honesty, and accountability guide our interactions with clients, partners, and team members, ensuring that trust is the foundation of every project."
    }
]

export const MAIN_ROUTES = [
    "/",
    "/about",
    "/services",
    "/projects",
    "/projects/",
    "/contact"
]

export const STATUS = [
    "Ongoing",
    "Completed"
]

export const CATEGORIES = [
    "Electrical Engineering",
    "Mechanical Engineering",
    "Electrical and Mechanical Engineering",
    "Installation Service"
]