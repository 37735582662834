const isEmpty = (string) => {
    return string?.length === 0;
}

const ValidateForm = (data, type) => {
    const { images, ...rest } = data;
    const {title, subtitle, description, category, startDate, endDate, client, status } = rest

    let error = {
        valid: false,
        message: "" 
    }

    let errors = {}

    if(isEmpty(title)){
        error.valid = true
        errors.title = {...error}
    }
    if(isEmpty(subtitle)){
        error.valid = true
        errors.subTitle = {...error}
    }
    if(isEmpty(description)){
        error.valid = true
        errors.description = {...error}
    }
    if(isEmpty(category)){
        error.valid = true
        errors.category = {...error}
    }
    if(isEmpty(startDate)){
        error.valid = true
        errors.startDate = {...error}
    }
    if(isEmpty(endDate)){
        error.valid = true
        errors.endDate = {...error}
    }
    if(isEmpty(client)){
        error.valid = true
        errors.client = {...error}
    }
    if(isEmpty(status)){
        error.valid = true
        errors.status = {...error}
    }
    if((images?.length === 0 || !images) && type === "CREATE"){
        error.valid = true
        errors.images = {...error}
    }

    if((Object.values(rest).every(field => !isEmpty(field)) && images?.length > 0) && type === "CREATE") errors.none = true;
    else if((Object.values(rest).every(field => !isEmpty(field)) && (images?.length === 0 || images?.length > 0)) && type === "UPDATE") errors.none = true;

    return errors;
}

export default ValidateForm;