import { createSlice } from "@reduxjs/toolkit";

interface ResponseSliceType{
    response:{
        valid: boolean,
        type: string,
        message: string
    }
}

const initialState:ResponseSliceType = {
    response:{
        valid: false,
        type: "",
        message: ""
    }
}

const ResponseSlice = createSlice({
    name: "response",
    initialState,
    reducers:{
        constructResponse: (state, action) => {
            state.response = action.payload
        }
    }
})

export const {
    constructResponse
} = ResponseSlice.actions;

export default ResponseSlice.reducer;