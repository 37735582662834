import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../../Store/Store';
import { useGetAllProjectsQuery } from '../../../../../../Store/Slices/Project/ProjectApiSlice';
import { setFetchError, setFetching, setProjects } from '../../../../../../Store/Slices/Project/ProjectSlice';
import { Loader } from '../../../../../UI/Loader';
import { getCategoryAndYear } from '../../../../../../Utils/Helper';
import Retry from '../../../../../UI/Retry';
import EmptyState from '../../../../../UI/EmptyState';
import Options from '../../../../../UI/Options/Options';
import { useNavigate } from 'react-router-dom';

const Projects:React.FC = () => {
    const {isFetching, isFiltering, isEmpty, isFetchError, filteredProjects:projects} = useSelector((state: RootState) => state.project);
    const dispatch:AppDispatch = useDispatch();
    const {refetch} = useGetAllProjectsQuery({});
    
    const navigate = useNavigate();

    const retryRequest = async() => {
        dispatch(setFetching(true))
        const {data, error} = await refetch();
        if(data){
            dispatch(setFetching(false))
            dispatch(setProjects([...data?.response]));
        }
        if(error){
            dispatch(setFetching(false))
            dispatch(setFetchError(true))
        }
    }

    const viewFn = (id: string) => {
        navigate(`/admin/home/view/${id}`)
    }

    const editFn = (id: string) => {
        navigate(`/admin/projects/${id}`)
    }

    return (
        <div className="w-full relative min-h-[400px]">
            {   
                isFetching || isFiltering
                ? 
                    <Loader/> 
                :
                isFetchError ?
                    <Retry
                        action={retryRequest}
                    />
                :
                isEmpty ?
                    <EmptyState context={"projects"}/>
                :
                <ul className="w-full max-w-[1000px] mt-9 mx-auto lg:flex md:flex-wrap gap-[2%]">
                    {projects?.map((value, index) => {
                        return (
                            <li key={index} className="w-full lg:w-[49%] rounded-lg border-[1px] mt-4 border-white-425">
                                <img src={value.images.find(image => image.mainImage)?.url} className="w-full aspect-video object-cover rounded-t-lg" alt="project-image" />
                                <div className="p-4 flex justify-between items-center">
                                    <div className="w-3/4 flex flex-col gap-2">
                                        <h5 className="text-[18px] font-medium w-full truncate overflow-hidden">{value?.title}</h5>
                                        <small className="text-[12px] text-white-550 font-medium">{getCategoryAndYear(value?.category, value?.endDate)}</small>
                                    </div>
                                    <Options
                                        id={value.code}
                                        viewFn={()=>viewFn(value.code)}
                                        editFn={()=>editFn(value.code)}
                                    />
                                </div>
                            </li>
                        )
                    })}
                </ul>
            }
        </div>
    )
}

export default Projects