import React from 'react'
import logo from '../../Assets/Icons/logo.svg'

const EmptyState:React.FC<{context: string}> = ({context}) => {
    return (
        <div className="top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute flex flex-col justify-center items-center gap-3">
            <img src={logo} className="w-8 h-8" alt="Logo" />
            <p className="text-[16px]text-center text-black-400">No {context} here..</p>
        </div>
    )
}

export default EmptyState