import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useVerifyOtpMutation } from '../../../Store/Slices/Auth/AuthApiSlice'
import { AppDispatch } from '../../../Store/Store'
import { useDispatch } from 'react-redux'
import { ProcessingRequest } from '../../UI/Loader'
import { constructResponse } from '../../../Store/Slices/Response/ResponseSlice'
import {ValidateOtpVerificationForm as ValidateForm} from './ValidateForm'
import Card from './Card/Card'
import Button from '../../UI/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OtpInput from 'react-otp-input';

interface ErrorType{
    valid: boolean,
    message: string
}
interface FormErrorType{
    otp: ErrorType,
    none: boolean
}

const VerifyOtp:React.FC = () => {
    const [verify] = useVerifyOtpMutation({});
    const dispatch:AppDispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    
    const [processing, setProcessing] = useState<boolean>(false)

    const [otp, setOtp] = useState<string>('')
    const [focusedInput, setFocusedInput] = useState<any>(null);

    const [inputErrors, setInputErrors] = useState<FormErrorType>({
        otp: {
            valid: false,
            message: ""
        },
        none: false
    })

    const handleSubmit = async(e:React.FormEvent) => {
        e.preventDefault();

        const errors:FormErrorType = ValidateForm(otp);
        setInputErrors(errors);

        if(errors.none){
            setProcessing(true)

            const payload = {
                otp: otp
            }

            const {data, error} = await verify(payload);
            
            let newResponse = {
                valid: true,
                message: "",
                type:""
            }

            if(data){
                setProcessing(false)

                newResponse.message = data?.response?.message;
                newResponse.type = "success"

                navigate("/admin/reset-password", {state: location , replace:true})
            }
            if(error){
                setProcessing(false)

                newResponse.message = "Verification unsuccessful. Please retry.";
                newResponse.type = "failure"
            }

            dispatch(constructResponse(newResponse))
        }
    }

    return (
        <Card>
            <div className="w-full bg-white-600 rounded-lg relative">
                
                {processing && <ProcessingRequest style={"top-0 right-0"}/>}

                <h2 className="text-[32px] font-medium mb-8">Verify OTP</h2>
                <form className=" w-full flex flex-col gap-4 mt-2" onSubmit={handleSubmit}>
                    <small className="text-red-600 text-sm">{inputErrors?.otp?.message}</small>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        containerStyle={{
                            padding: '0',
                            gap: '1rem',
                            marginBottom: '1.5rem',
                        }}
                        inputStyle={{
                            width: '100%',
                            height: '95px',
                            padding: '2rem 0.7rem',
                            border: '0.15rem solid rgba(128, 128, 128, 0.65)',
                            textAlign: 'center',
                            fontSize: "44px"
                        }}
                        renderInput={(props, index) => (
                            <input
                                {...props}
                                style={{
                                    ...props.style,
                                    borderColor: focusedInput === index ? 'rgba(239, 132, 27, 1)' : 'rgba(128, 128, 128, 0.65)', 
                                }}
                                onFocus={() => setFocusedInput(index)}
                                onBlur={() => setFocusedInput(null)}
                            />
                        )}
                        skipDefaultStyles
                    />
                    <Button
                        type='submit'
                        style={"bg-orange w-full mx-auto mb-5 cursor-pointer text-white-600 block py-3 rounded-md"}
                        text="Verify"
                        action={()=>{}}
                    />
                </form>
                <p className="text-center">Didn't receive the email? <span><Link to="/admin/verify-email" className="text-orange font-semibold pb-1 border-b-orange border-b-[1.5px]">Click here</Link></span></p>
                <Link to="/admin/login" className="text-center text-sm flex items-center gap-2 justify-center mt-4"><ArrowBackIcon style={{fontSize: 14, color: 'black'}}/>Go back to login</Link>
            </div>
        </Card>
    )
}

export default VerifyOtp