import { createApi, fetchBaseQuery, BaseQueryApi, FetchArgs } from '@reduxjs/toolkit/query/react'
import { RootState } from '../Store';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PROD_URL,
    credentials: "include",
    prepareHeaders: (headers, {getState}) => {
        const state = getState() as RootState;
        const { token } = state.auth;

        if(token){
            headers.set("Authorization", `Bearer ${token}`)
        }

        return headers;
    }
})

const reAuthQuery = async(args:FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
    const state = api.getState() as RootState;
    const { token } = state.auth;

    let result = await baseQuery(args, api, extraOptions);
    const {error} = result;

    if(error?.status === 403 && token){
        const reAuthResponse = await baseQuery("/api/v1/auth/refresh", api, extraOptions)
        const{data, error:reAuthError} = reAuthResponse
        if(data){
            console.log(data)
            // At this point, we want to set the new token and then make the request.
            result = await baseQuery(args, api, extraOptions);
        }
        if(reAuthError){
            console.log(reAuthError);
        }
    }

    return result;

}

export const apiSlice = createApi({
    reducerPath: "Api",
    baseQuery: reAuthQuery,
    endpoints: (builder) => ({})
})

