import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../../../Store/Store'; 
import { getCategoryAndYear } from '../../../../../../../Utils/Helper';
import ProjectModal from '../../../../../../UI/Modals/Project/ProjectModal'; 
import DeleteProjectModal from '../../../../../../UI/Modals/Project/DeleteProjectModal'; 
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDeleteProjectMutation } from '../../../../../../../Store/Slices/Project/ProjectApiSlice';
import { constructResponse } from '../../../../../../../Store/Slices/Response/ResponseSlice';
import Card from '../../../../Card';
import { setFetching } from '../../../../../../../Store/Slices/Project/ProjectSlice';

interface ImagesType{
    main: any,
    others: any[]
}

const CurrentProject:React.FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);
    
    const { currentProject } = useSelector((state: RootState) => state.project)
    const navigate = useNavigate();
    
    const {
        title,
        subTitle,
        description,
        category,
        endDate,
        code,
        images
    } = currentProject;

    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [projectImages, setProjectImages] = useState<ImagesType>({
        main: "",
        others: []
    })

    useEffect(()=>{
        if(images){
            setProjectImages({
                main: images.find(image => image?.mainImage),
                others: images.filter(image => !image?.mainImage)
            })
        }

    },[images])

    const [deleteFnc] = useDeleteProjectMutation({});
    const dispatch:AppDispatch = useDispatch();

    const deleteProject = async() => {
        setProcessing(true)
        const {data, error} = await deleteFnc(code);

        let newResponse = {
            valid: true,
            message: "",
            type:""
        }

        if(data){
            setProcessing(false)
            setIsOpen(false)
            
            newResponse.message = data?.response?.message
            newResponse.type = "success"
            dispatch(setFetching(true))
            navigate("/admin/home")
        }
        if(error){
            setProcessing(false)

            newResponse.message = "Unable to delete project, please try again"
            newResponse.type = "failure"
        }

        dispatch(constructResponse(newResponse))
    }
    

    return (
        <Card>
            <div className="w-full py-6 px-[1%]" data-scroll-section>
                <div className="w-full flex justify-between items-start gap-6 border-b-[1px] border-b-white-400">
                    <div className="pb-8">
                        <h1 className="text-[32px] font-medium leading-none mb-4">{title}</h1>
                        
                        <small className="text-sm text-white-550">{getCategoryAndYear(category, endDate)}</small>
                    </div>
                    <div className="w-full max-w-[300px] flex justify-end gap-5">
                        <button type="button" className="w-[97px] flex items-center text-white-600 bg-edit gap-2 p-3 rounded-lg" onClick={()=>navigate(`/admin/projects/${code}`)}>
                            <Edit style={{color: 'white'}}/>
                            <span className="text-[16px]">Edit</span>
                        </button>
                        <button type="button" className="w-[116px] flex items-center text-white-600 bg-delete gap-2 p-3 rounded-lg" onClick={()=>setIsOpen(true)}>
                            <Delete style={{color: 'white'}}/>
                            <span>Delete</span>
                        </button>
                    </div>
                </div>
                
                <p className="text-[16px] md:text-[18px] my-7 text-gray">{subTitle}</p>
                
                <img src={projectImages.main?.url} className="w-full aspect-video lg:aspect-square h-[600px] object-cover" alt="Image" />

                <p className="text-[14px] md:text-[20px] text-gray my-12 whitespace-pre-wrap">{description}</p>
                
                <ul className="w-full flex items-center flex-wrap gap-[2%] my-8">
                    {projectImages.others.map((value, index) => {
                    return (
                        <li key={index} className="w-full md:max-w-[265px] aspect-square mt-8 cursor-pointer" onClick={()=>setSelectedImage(value)}>
                            <img src={value?.url} className="w-full h-full" alt="Image"/>
                        </li>
                    )
                    })}
                </ul>
                {isOpen && <DeleteProjectModal isOpen={isOpen} processing={processing} close={()=>setIsOpen(false)} action={deleteProject} />}
                {selectedImage && <ProjectModal src={selectedImage?.url} isOpen={true} close={()=>setSelectedImage(null)}/>}
            </div>
        </Card>
    )
}

export default CurrentProject