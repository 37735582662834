import React, { ReactNode, useState, useEffect } from 'react'
import { NavLink as Link, useLocation, useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../Assets/Icons/logo.svg'
import ContactUs from './ContactUs';

interface NavLinkType {
    to: string,
    onClick: ()=> void,
    children: ReactNode
}

const NavLink:React.FC<NavLinkType> = ({to, children, onClick}) => {
    const { pathname } = useLocation();
    const defaultClass = "text-[16px] pb-1 border-b-[2px] "
    const activeClass = "border-b-orange text-orange font-medium";
    const inActiveClass = "text-white-500 font-normal border-b-transparent";

    const className = defaultClass + (pathname === "/" && to === "/home" ? activeClass : pathname.includes(to) ? activeClass : inActiveClass);

    return (
        <Link to={to === "/home" ? "/" : to} onClick={onClick} className={className}>{children}</Link>
    )
}

const NavLinks:React.FC<{close: ()=>void}> = ({close}) => {
    return (
        <div className="bg-black-600 pt-24 pb-6 px-4 w-full flex-col items-center justify-between flex md:flex-row md:bg-transparent md:pt-0 md:pb-0 md:px-0">
            <nav className="flex flex-col items-center gap-9 md:flex-row">
                <NavLink to={"/home"} onClick={close}>Home</NavLink>
                <NavLink to={"/services"} onClick={close}>Services</NavLink>
                <NavLink to={"/projects"} onClick={close}>Our Projects</NavLink>
                <NavLink to={"/about"} onClick={close}>About Us</NavLink>
            </nav>
            <ContactUs close={close} style={" bg-orange mx-4 mt-8 md:mt-0 md:mx-0 md:max-w-[144px] hover:bg-hover transition-all duration-500 ease-out"}/>
        </div>
    )
}

const Navbar:React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState<boolean | null>(null);

    const toggleNav = () => {
        if(isOpen){
            setIsOpen(!isOpen)
        }
        if(isOpen === false){
            setIsOpen(true)
        }
        if(isOpen === null){
            setIsOpen(true);
        }
    }

    const goHome = () => {
        setIsOpen(false)
        navigate("/")
    }

    useEffect(()=>{
        const handleScroll = () => {
            if(isOpen) setIsOpen(false);
        }
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    },[])

    const animation = isOpen ? "animate-nav-in": isOpen === false ? "animate-nav-out" : "-translate-y-full"
    const bg = location.pathname.includes("/projects/") ? "bg-black-600" : "blur-bg"

    return (
        <header className={"w-full fixed top-0 left-0 right-0 z-10 flex justify-between items-center dark py-5 px-[5%] " + bg} id="navbar">
            <div className="w-full flex items-center justify-between">
                <img src={logo} className="w-8 cursor-pointer z-10" onClick={goHome} alt="Logo"/>
                <div className={"md:w-2/3 hidden md:flex"}>
                    <NavLinks close={()=>{}}/>
                </div>
            </div>
            <div className="block z-10 md:hidden">
                {!isOpen ? 
                    <MenuIcon style={{
                            color: 'white', 
                            fontSize: 30, 
                            cursor: 'pointer'
                        }} 
                        onClick={toggleNav}
                    /> 
                    :
                    <CloseIcon style={{
                        color: 'white', 
                        fontSize: 30, 
                        cursor: 'pointer'}} 
                        onClick={toggleNav}
                    />
                }
            </div>
            <div className={"w-full md:w-0 md:hidden fixed top-0 left-0 " + animation}><NavLinks close={toggleNav}/></div>
        </header>
    )
}

export default Navbar