import React, { useRef } from 'react'
import { REVIEWS } from '../../../../../Utils/Constants'
import Control from './Control'

const Reviews:React.FC = () => {
    const sliderRef = useRef<HTMLDivElement>(null)

    const scroll = (isForward:boolean) => {
        const slideWidth = 400;
        const scrollAmount = 2 * slideWidth;

        if (isForward) {
            sliderRef.current!.scrollTo({
                left: sliderRef.current!.scrollLeft + scrollAmount,
                behavior: 'smooth'
            });
        } else {
            sliderRef.current!.scrollTo({
                left: sliderRef.current!.scrollLeft - scrollAmount,
                behavior: 'smooth'
            });
        }
    }
    return (
        <section className="w-full py-16 light-section" data-scroll-section>
            <div className="flex justify-between items-start px-[5%]">
                <div>
                    <h1 className="text-[32px] md:text-[74px]">Our Reviews</h1>
                    <p className="text-sm text-gray max-w-[400px] md:max-w-[600px]">
                        These reviews highlight the trust, dedication, and excellence that we bring to each project.      
                    </p>
                </div>
                <Control
                    func={scroll}
                />
            </div>
            <div ref={sliderRef} className="w-[95%] overflow-x-scroll reviews-list mt-14 mb-40 float-right flex-nowrap scrollbar-hide flex items-start gap-5 transition-all ease-in-out duration-500">
                {REVIEWS.map((value, index) => {
                    return (
                        <div key={index} className="w-[280px] md:w-[400px] flex-shrink-0 bg-white-450 rounded-lg flex flex-col gap-5 p-6">
                            <p className="text-black-400 text-[12px] md:text-[18px]">{value.review}</p>
                            <h4 className="text-[24px]">{value.name}</h4>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default Reviews