import React, {useState, useEffect, useCallback} from 'react'
import { AppDispatch } from '../../../../Store/Store'
import { useDispatch } from 'react-redux'
import { useCreateProjectMutation, useEditProjectMutation, useGetSingleProjectQuery } from '../../../../Store/Slices/Project/ProjectApiSlice'
import { constructResponse } from '../../../../Store/Slices/Response/ResponseSlice'
import { CATEGORIES, STATUS } from '../../../../Utils/Constants'
import { ProcessingRequest } from '../../../UI/Loader'
import Input from '../../../UI/Input'
import ValidateForm from './ValidateForm'
import DateFilter from '../../../UI/DateFilter'
import Button from '../../../UI/Button'
import ImageUpload from './Image/ImageUpload'
import Dropdown from '../../../UI/Dropdown'
import Card from '../Card'
import { useNavigate, useParams } from 'react-router-dom'
import { setFetching } from '../../../../Store/Slices/Project/ProjectSlice'

interface FormType{
    title: string,
    subTitle: string,
    description: string,
    category: string | undefined,
    startDate: string,
    endDate: string,
    client: string,
    status: string | undefined
}
interface ErrorType{
    valid: boolean,
    message: string
}
interface FormErrorType{
    title: ErrorType,
    subTitle: ErrorType,
    description: ErrorType,
    category: ErrorType,
    startDate: ErrorType,
    endDate: ErrorType,
    client: ErrorType,
    status: ErrorType,
    images: ErrorType,
    none: boolean
}
interface ProjectUpsertType{
    isUpdate: boolean
}

const ProjectUpsert:React.FC<ProjectUpsertType> = ({isUpdate}) => {
    const {id} = useParams();
    const [create] = useCreateProjectMutation({});
    const [update] = useEditProjectMutation({})
    const {refetch} = useGetSingleProjectQuery(id)
    const dispatch:AppDispatch = useDispatch()
    const navigate = useNavigate();

    const [processing, setProcessing] = useState<boolean>(false)

    const [inputFields, setInputFields] = useState<FormType>({
        title: "",
        subTitle: "",
        description: "",
        category: "",
        startDate: "",
        endDate: "",
        client: "",
        status: ""
    })
    
    const [inputErrors, setInputErrors] = useState<FormErrorType>({
        title: {
            valid: false,
            message: ""
        },
        subTitle: {
            valid: false,
            message: ""
        },
        description: {
            valid: false,
            message: ""
        },
        category: {
            valid: false,
            message: ""
        },
        startDate: {
            valid: false,
            message: ""
        },
        endDate: {
            valid: false,
            message: ""
        },
        client: {
            valid: false,
            message: ""
        },
        status: {
            valid: false,
            message: ""
        },
        images: {
            valid: false,
            message: ""
        },
        none: false
    })

    useEffect(()=>{
        if(processing){
            document.getElementById("upsert")?.scrollIntoView({
                behavior: "smooth",
                block: "start"
            })
        }
    },[processing])

    const [images, setImages] = useState<any[]>([]);
    const [allowableImages, setAllowableImages] = useState<number>(5);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;

        setInputFields(prev => {
            return {...prev, [name]:value}
        })
    }

    const saveCategory = (value: string | undefined) => {

        setInputFields(prev => {
            return {...prev, category: value}
        })
    }
    
    const saveStatus = (value: string | undefined) => {

        setInputFields(prev => {
            return {...prev, status: value}
        })
    }

    const saveStartDate = useCallback((date: string) => {
        setInputFields(prev => {
            return {...prev, startDate: date}
        })
    }, [])

    const saveEndDate = useCallback((date: string) => {
        setInputFields(prev => {
            return {...prev, endDate: date}
        })
    }, [])

    const saveImages = (imagesList: string[]) => {
        setImages([...imagesList])
    }

    const upsertProject = async(e:React.FormEvent) => {
        e.preventDefault();

        const payload = {
            ...inputFields,
            images
        }

        const action = isUpdate ? "UPDATE" : "CREATE"

        const errors:FormErrorType = ValidateForm(payload, action);
        setInputErrors(errors);

        if(errors?.images?.valid){
            dispatch(constructResponse({
                valid: true,
                message: "No images have been selected",
                type:"failure"
            }))
        }
        else if(errors.none){
            setProcessing(true)

            const {data, error} = isUpdate ?  await update({id, ...payload}) : await create(payload)

            let newResponse = {
                valid: true,
                message: "",
                type:""
            }

            if(data){
                setProcessing(false)
                
                newResponse.message = data?.response?.message
                newResponse.type = "success"

                setInputFields({
                    title: "",
                    subTitle: "",
                    description: "",
                    category: "",
                    startDate: "",
                    endDate: "",
                    client: "",
                    status: ""
                })
                dispatch(setFetching(true))
                navigate("/admin/home")
            }
            if(error){
                setProcessing(false)

                const action = isUpdate ? "update" : "create"

                newResponse.message = `Unable to ${action} project. Please retry.`
                newResponse.type = "failure"
            }

            dispatch(constructResponse(newResponse))
        }
    }

    const getProject = useCallback(async () => {
        if(isUpdate){
            setProcessing(true)
            const {data, error} = await refetch();
            if(data){
                setProcessing(false)

                const currentCategory = CATEGORIES.find(category => {
                    return category.toLowerCase() === data?.response?.category.split("_").join(" ").toLowerCase();
                })
                
                const currentStatus = STATUS.find(status => {
                    return status.toLowerCase() === data?.response?.status.toLowerCase();
                })

                setInputFields({
                    title: data?.response?.title,
                    subTitle: data?.response?.subTitle,
                    description: data?.response?.description,
                    category: currentCategory,
                    startDate: data?.response?.startDate,
                    endDate: data?.response?.endDate,
                    client: data?.response?.client,
                    status: currentStatus
                })
                
                let newResponse = {
                    valid: true,
                    message:"",
                    type:"warning"
                }

                let imageSpaceLeft:number = 0;
                
                if(data?.response?.images?.length === allowableImages){
                    newResponse.message = "WARNING! You can't upload anymore images."
                    dispatch(constructResponse(newResponse))
                }
                else if(data?.response?.images?.length < allowableImages){
                    imageSpaceLeft = 5 - data?.response?.images?.length;

                    newResponse.message = `WARNING! You can only upload ${imageSpaceLeft} image(s).`
                    dispatch(constructResponse(newResponse))
                }
                
                setAllowableImages(imageSpaceLeft);
            }
            if(error){
                setProcessing(false)
            }
        }
    },[isUpdate, allowableImages, dispatch, refetch])

    useEffect(() => {
        getProject()
    },[getProject])

    return (
        <Card>
            <div className="w-full pb-20 relative" id="upsert">
                {processing && <ProcessingRequest style={"top-5 right-5"}/>}
                <div className="w-full border-b-white-425 border-b-[1px] pb-5">
                    <h1 className="text-[32px] font-bold">{isUpdate ? "Edit Project" : "Add project"}</h1>
                    <p className="text-[16px] text-white-325">{isUpdate ? "Update Project Details and Make Changes as Needed" : "Create and input details for a new project."}</p>
                </div>
                <form className="w-full lg:grid grid-cols-[1.7fr_1fr] gap-2 mt-6" onSubmit={upsertProject}>
                    <div className="w-full p-3 border-white-425 border-[1px] rounded-lg">
                        <p className="text-sm text-white-495">DETAILS</p>

                        <div className="flex flex-col gap-3 mt-5">
                            <Input
                                title="Project Title" 
                                type="text" 
                                id="title" 
                                name="title" 
                                placeholder="Enter the project title"
                                value={inputFields.title} 
                                error={inputErrors.title}
                                onChange={handleChange}
                            />
                            <Input
                                title="Project Subtitle" 
                                type="text" 
                                id="subTitle" 
                                name="subTitle" 
                                placeholder="Enter the project subtitle"
                                value={inputFields.subTitle} 
                                error={inputErrors.subTitle}
                                onChange={handleChange}
                            />
                            <Input
                                title="Project Description" 
                                type="message" 
                                id="description" 
                                name="description" 
                                placeholder="Provide a brief description of the project"
                                value={inputFields.description} 
                                error={inputErrors.description}
                                onChange={handleChange}
                            />
                            <Dropdown
                                title="Project Category"
                                defaultPlaceHolder='Select a project category'
                                error={inputErrors.category}
                                value={inputFields.category}
                                list={CATEGORIES}
                                save={saveCategory}
                            />
                            <div className="w-full flex flex-col md:flex-row justify-between items-center gap-3">
                                <DateFilter
                                    title="Project Start Date"
                                    placeholder="Select the start date"
                                    error={inputErrors.startDate}
                                    value={inputFields.startDate}
                                    handleChange={saveStartDate}
                                />
                                <DateFilter
                                    title="Project End Date"
                                    placeholder="Select the end date"
                                    error={inputErrors.endDate}
                                    value={inputFields.endDate}
                                    handleChange={saveEndDate}
                                />
                            </div>
                            <Input
                                title="Client Name" 
                                type="text" 
                                id="client" 
                                name="client" 
                                placeholder="Enter the client's name"
                                value={inputFields.client} 
                                error={inputErrors.client}
                                onChange={handleChange}
                            />
                            <Dropdown
                                title="Status"
                                defaultPlaceHolder="Select project's status"
                                error={inputErrors.status}
                                value={inputFields.status}
                                list={STATUS}
                                save={saveStatus}
                            />
                        </div>

                    </div>
                    <div className="w-full h-full p-3 border-white-425 border-[1px] rounded-lg">
                        <div className="w-full h-full flex flex-col justify-between">
                            <ImageUpload 
                                save={saveImages} 
                                allowableImages={allowableImages}
                            />
                            <Button
                                type="submit"
                                text="Save and Publish"
                                action={()=>{}}
                                style="p-3 mt-4 lg:mt-0 text-white-600 bg-orange rounded-lg w-full"
                            />
                        </div>
                        
                    </div>
                </form>
            </div>
        </Card>
    )
}

export default ProjectUpsert