import { apiRoutes } from "../../../Config/ApiRoutes";
import { apiSlice } from "../../Api/apiSlice";

const AuthApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        register: builder.mutation({
            query: payload => ({
                url: apiRoutes.auth.REGISTER,
                method: "POST",
                body: {...payload}
            })
        }),
        login: builder.mutation({
            query: payload => ({
                url: apiRoutes.auth.LOGIN,
                method: "POST",
                body: {...payload}
            })
        }),
        verifyEmail: builder.mutation({
            query: payload => ({
                url: apiRoutes.auth.EMAIL,
                method: "POST",
                body: {...payload}
            })
        }),
        verifyOtp: builder.mutation({
            query: payload => ({
                url: apiRoutes.auth.OTP,
                method: "POST",
                body: {...payload}
            })
        }),
        resetPassword: builder.mutation({
            query: payload => ({
                url: apiRoutes.auth.RESET,
                method: "PUT",
                body: {...payload}
            })
        }),
        refresh: builder.mutation({
            query: () => ({
                url: apiRoutes.auth.REFRESH,
                method: "GET"
            })
        }),
        logout: builder.mutation({
            query: () => ({
                url: apiRoutes.auth.LOGOUT,
                method: "POST",
            })
        })
    })
})

export const {
    useRegisterMutation,
    useLoginMutation,
    useVerifyEmailMutation,
    useVerifyOtpMutation,
    useResetPasswordMutation,
    useRefreshMutation,
    useLogoutMutation
} = AuthApiSlice