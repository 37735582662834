import React, { useState, useRef, useEffect } from 'react'
import OptionsList from './OptionsList';
import { useDeleteProjectMutation } from '../../../Store/Slices/Project/ProjectApiSlice';
import { AppDispatch } from '../../../Store/Store';
import {useDispatch} from 'react-redux'
import { setFetching } from '../../../Store/Slices/Project/ProjectSlice';
import { constructResponse } from '../../../Store/Slices/Response/ResponseSlice';
import DeleteProjectModal from '../Modals/Project/DeleteProjectModal';

interface OptionsType{
    id: string,
    viewFn: () => void,
    editFn: () => void
}

const Options:React.FC<OptionsType> = ({id, viewFn, editFn}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [deleteProjectOpen, setDeleteProjectOpen] = useState<boolean>(false);
    const optionsRef = useRef<HTMLDivElement>(null);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        const closeDropdownOnOutsideClick = (e: MouseEvent) => {
            if (isOpen && optionsRef.current && !optionsRef.current.contains(e.target as Node)) {
                setIsOpen(false);
            }
        };
    
        document.addEventListener('click', closeDropdownOnOutsideClick);
    
        return () => {
            document.removeEventListener('click', closeDropdownOnOutsideClick);
        };
    }, [isOpen]);

    const [processing, setProcessing] = useState<boolean>(false);

    const [deleteFnc] = useDeleteProjectMutation({});
    const dispatch:AppDispatch = useDispatch();

    const deleteProject = async() => {
        setProcessing(true)
        const {data, error} = await deleteFnc(id);

        let newResponse = {
            valid: true,
            message: "",
            type:""
        }

        if(data){
            setProcessing(false)
            setDeleteProjectOpen(false)
            
            newResponse.message = data?.response?.message
            newResponse.type = "success"

            dispatch(setFetching(true))
        }
        if(error){
            setProcessing(false)

            newResponse.message = "Unable to delete image, please try again."
            newResponse.type = "failure"
        }

        dispatch(constructResponse(newResponse))
    }

    return (
        <>
            <div ref={optionsRef} className="w-auto relative options">
                <div className="w-auto flex items-center gap-1 cursor-pointer" onClick={toggle}>
                    <div className="w-[2px] h-[2px] border-[2px] rounded-full bg-black-600"/>
                    <div className="w-[2px] h-[2px] rounded-full border-[2px] bg-black-600"/>
                    <div className="w-[2px] h-[2px] rounded-full border-[2px] bg-black-600"/>
                </div>
                <div className="absolute w-full pb-12">
                    {isOpen && 
                        <OptionsList  
                            id={id}
                            viewFn={viewFn}
                            editFn={editFn}
                            openDeleteModal={()=>setDeleteProjectOpen(true)}
                        />
                    }
                </div>
                {
                    deleteProjectOpen &&
                    <DeleteProjectModal 
                        isOpen={deleteProjectOpen} 
                        processing={processing} 
                        close={()=>setDeleteProjectOpen(false)}
                        action={deleteProject}
                    />
                }
            </div>
        </>
    )
}

export default Options