import React, {ReactNode} from 'react'
import { BottomSquares, TopSquares } from './Squares'
import logo from '../../../../Assets/Icons/auth-logo.svg'
import authCardBg from '../../../../Assets/Images/Auth/auth-card-bg.png'
import OverviewWrapper from '../../../UI/OverviewWrapper'

const Card:React.FC<{children: ReactNode}> = ({children}) => {
    return (
        <div className="w-full h-screen md:grid md:grid-cols-2 lg:grid-cols-[1.2fr_2fr]">
            <div className="w-full py-12 px-[7%] auth-card overflow-y-scroll">
                <img src={logo} className="w-[121.2px] h-[45.26px]" alt="Logo"/>
                <div className="w-full mt-16">
                    {children}
                </div>
            </div>
            <OverviewWrapper
                style={"hidden md:block px-[5%] bg-cover bg-no-repeat "}
                imageUrl={authCardBg}
            >
                <TopSquares/>
                <BottomSquares/>
                <div className="absolute bottom-10">
                    <h1 className="text-white-600 md:text-[48px] lg:text-[72px] leading-none">Engineering is dream turned reality</h1>
                    <p className="text-[18px] text-white-600 mt-4">
                        Whether it's the dream of building a towering skyscraper, designing a new technology, or creating a more efficient machine, it all starts as an intangible vision.
                    </p>
                </div>
            </OverviewWrapper>
            {/* <div className="hidden md:block px-[5%] bg-auth-card bg-cover bg-no-repeat">
                
            </div> */}
        </div>
    )
}

export default Card