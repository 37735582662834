import React from 'react'

interface BulletPointType{
    text: string,
    style: string
}

const BulletPoint:React.FC<BulletPointType> = ({text, style}) => {
    return (
        <div className={"text-orange text-[16px] flex items-center gap-2 " + style}>
            <div className="w-1 h-1 mt-1 rounded-full bg-orange"/>
            <p>{text}</p>
        </div>
    )
}

export default BulletPoint