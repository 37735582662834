import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../Store/Store';
import search from '../../Assets/Icons/search.svg'
import { searchProject } from '../../Store/Slices/Project/ProjectSlice';

const SearchBar:React.FC = () => {
    const [searchKey, setSearchKey] = useState<string>('');

    const dispatch:AppDispatch = useDispatch();
    
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(e.target.value);
        dispatch(searchProject(e.target.value))
    }

    return (
        <div className="flex items-center p-3 border-[1px] w-full border-white-425 rounded-lg gap-4">
            <img src={search} alt=""/>
            <input title="Search" type="text" placeholder='Search all projects' className="w-full focus:outline-none" value={searchKey} onChange={handleChange} />
        </div>
    )
}

export default SearchBar