import React from 'react'
import engineer from '../../../Assets/Images/Landing/thinking-engineer.png'
import Button from '../../UI/Button'
import { useNavigate } from 'react-router-dom'

const Products:React.FC = () => {
    const navigate = useNavigate();

    return (
        <section className="w-full py-16 md:grid grid-cols-2 gap-5 px-[5%] light-section" data-scroll-section>
            <div>
                <h1 className="text-[64px] md:text-[130px] flex items-start">50 <span className="text-[32px] md:text-7xl mt-4 md:mt-6">+</span></h1>
                <small className="text-gray text-sm">Ground breaking products</small>
                <div className="w-full md:max-w-[429.59px] mt-20 md:mt-24 flex flex-col gap-18">
                    <p className="text-[28px]">
                        Explore our portfolio to gain insights into our diverse engineering projects and find inspiration for your own engineering aspirations.
                    </p>
                    <Button
                        type="button"
                        text="Explore projects"
                        action={()=>navigate("/projects")}
                        style="bg-orange text-white-600 text-[20px] w-full md:max-w-[229px] py-[17px] px-3 rounded-lg mt-10"
                    />
                </div>
            </div>
            <img src={engineer} className="object-cover w-full mt-20 md:mt-0 md:h-full" alt="engineer"/>
        </section>
    )
}

export default Products