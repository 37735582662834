import React from 'react'
import logo from "../../Assets/Icons/logo.svg"

export const Loader:React.FC = () => {
    return (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center text-center items-center gap-4">
            <img src={logo} className="w-8 h-8" alt="Logo"/>
            <p>Please wait..</p>
            <div className="w-8 h-8 rounded-full border-t-orange border-r-orange border-l-orange border-b-black-600 border-[1.5px] animate-spin"/>
        </div>
    )
}

export const ProcessingRequest:React.FC<{style: string}> = ({style}) => {
    return (
        <div className={"w-8 h-8 absolute rounded-full border-t-orange border-r-orange border-l-orange border-b-black-600 border-[1.5px] animate-spin " + style }/>
    )
}