import React from 'react'
import BulletPoint from '../../UI/BulletPoint'

const About:React.FC = () => {
    return (
        <section className="bg-black-600 w-full px-[5%] py-14 md:grid md:grid-cols-[1fr_2fr] items-start dark-section" data-scroll-section>
            <BulletPoint
                style={"mb-6 md:mb-0"}
                text={"About Omecon"}
            />
            <div className="w-full">
                <h2 className="text-white-600 text-[24px] md:text-[44px] md:leading-none">
                    At Omecon Engineering Limited, we deliver engineering excellence in building services with skilled professionals and innovative solutions.
                </h2>
                <div className="w-full border-t-white-600 border-[1px] my-6 md:my-12"/>
                <div className="w-full max-w-[436.74px]">
                    <small className="text-sm text-white-300">
                        The company is a result-oriented engineering company that builds value for its clients through its employees by creativity, resourcefulness and by dealing with every client in an open ethical manner. 
                    </small>
                </div>
            </div>
        </section>
    )
}

export default About