import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../Store/Store';
import { getCategoryAndYear } from '../../../../../../Utils/Helper';
import ProjectModal from '../../../../../UI/Modals/Project/ProjectModal';

interface ImagesType{
  main: any,
  others: any[]
}

const CurrentProject:React.FC = () => {
  const { currentProject } = useSelector((state: RootState) => state.project)
  
  const {
    title,
    subTitle,
    description,
    category,
    endDate,
    images
  } = currentProject;

  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [projectImages, setProjectImages] = useState<ImagesType>({
    main: "",
    others: []
  })

  useEffect(()=>{
    if(images){
      setProjectImages({
        main: images.find(image => image?.mainImage),
        others: images.filter(image => !image?.mainImage)
      })
    }
  },[images])

  return (
    <div className="w-full" data-scroll-section>
      <div className="pb-6 border-b-[1px] border-b-black-500 flex flex-col gap-6">
        <h1 className="text-[32px] md:text-[64px] font-medium leading-none max-w-[1061.34px]">{title}</h1>
        
        <small className="text-[12px] text-gray">{getCategoryAndYear(category, endDate)}</small>
      </div>
      
      <p className="text-[16px] md:text-[18px] my-7 text-gray">{subTitle}</p>
      
      <img src={projectImages.main?.url} className="w-full aspect-square object-cover lg:aspect-video" alt="Project-Image" />

      <p className="text-[14px] md:text-[20px] text-gray my-12 whitespace-pre-wrap">{description}</p>
      
      <ul className="w-full flex items-center flex-wrap gap-[3%] my-8">
        {projectImages.others.map((value, index) => {
          return (
            <li key={index} className="bg-gray w-full max-w-[115px] md:max-w-[265px] aspect-square mt-3 md:mt-8 cursor-pointer" onClick={()=>setSelectedImage(value)}>
              <img src={value?.url} className="w-full h-full object-cover" alt="Project-Image"/>
            </li>
          )
        })}
      </ul>
      
      {selectedImage && <ProjectModal src={selectedImage?.url} isOpen={true} close={()=>setSelectedImage(null)}/>}
    </div>
  )
}

export default CurrentProject