import React, { useState, useEffect, useCallback } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../Store/Store'
import { useRefreshMutation } from '../Store/Slices/Auth/AuthApiSlice';
import { setCredentials } from '../Store/Slices/Auth/AuthSlice';
import { Loader } from '../Components/UI/Loader';

const ReAuth:React.FC = () => {
    const { token } = useSelector((state:RootState) => state.auth);
    const [validating, setValidating] = useState<boolean>(true)
    const dispatch:AppDispatch = useDispatch();
    const [ refresh ] = useRefreshMutation()
    const location = useLocation()

    const refreshApp = useCallback(async () => {
        const {data, error} = await refresh({});

        if(data){
            const user = {
                user: {
                    firstName: data?.response?.firstName,
                    lastName: data?.response?.lastName,
                    email: data?.response?.email,
                },
                token: data?.response?.token,
                isLoggedIn: true
            }

            dispatch(setCredentials(user));

            setValidating(false);
        }
        if(error){
            setValidating(false);
        }
    },[refresh, dispatch])

    useEffect(()=>{
        if(!token){
            refreshApp();
        }
        else setValidating(false);
    },[token, refreshApp])

    return validating ? <Loader/> : (!validating && token) ? <Outlet/> : <Navigate to="/admin/login" state={{from: location}} replace/>
}

export default ReAuth;