import React from 'react'
import Modal from '../Modal'
import { ProcessingRequest } from '../../Loader'
import Logout from '../../SideBar/Icons/Logout'

interface LogoutModalType{
    isOpen: boolean,
    processing: boolean,
    close: () => void,
    action: () => void
}

const LogoutModal:React.FC<LogoutModalType> = ({isOpen, processing, close, action}) => {
    return (
        <Modal
            isOpen={isOpen}
            close={close}
        >
            <div className="w-full max-w-[500px] bg-white-600 z-50 rounded-md p-6 top-1/2 left-1/2 fixed -translate-x-1/2 -translate-y-1/2">
                {processing && <ProcessingRequest style={"top-3 right-3"}/>}

                <div className="w-full flex flex-col py-5 justify-center items-center">
                    <Logout/>
                    <h4 className="text-[20px] font-medium mt-3">Logout</h4>
                    <p className="mt-2 text-center text-white-495 font-normal">Are you sure you want to logout?</p>
                    <div className="mt-6 flex items-center gap-5 w-full max-w-[350px]">
                        <button type="button" className="text-black-600 rounded-md font-medium border-[1px] text-[16px] border-white-495 w-full px-2 py-3" onClick={close}>No, cancel</button>
                        <button type="button" className="text-white-600 rounded-md font-medium border-[1px] text-[16px] border-red-500 w-full bg-red-500 px-2 py-3" onClick={action}>Yes, logout</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default LogoutModal