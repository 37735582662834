import React from 'react'

const Home:React.FC<{color: string}> = ({color}) => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.53459 15.6556H11.8679M13.6849 18.9889H6.71763C4.79367 18.9889 3.234 17.5006 3.234 15.6646V11.5998C3.234 11.0096 2.98832 10.4436 2.55099 10.0263C1.53776 9.05935 1.66948 7.45731 2.82834 6.65303L8.15228 2.95809C9.37382 2.11032 11.0287 2.11032 12.2502 2.9581L17.5742 6.65303C18.733 7.45731 18.8647 9.05935 17.8515 10.0263C17.4142 10.4436 17.1685 11.0096 17.1685 11.5998V15.6646C17.1685 17.5006 15.6088 18.9889 13.6849 18.9889Z" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    )
}

export default Home