import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../../../Store/Store';
import { useParams } from 'react-router-dom';
import { setCurrentProject, setFetchError, setFetching } from '../../../../../../../Store/Slices/Project/ProjectSlice';
import { useGetSingleProjectQuery } from '../../../../../../../Store/Slices/Project/ProjectApiSlice';
import { Loader } from '../../../../../../UI/Loader';
import Retry from '../../../../../../UI/Retry';
import EmptyState from '../../../../../../UI/EmptyState';
import Project from './Project'

const CurrentProject:React.FC = () => {
    const {isFetching, isEmptyProject, isFetchError} = useSelector((state: RootState) => state.project);
    const { id } = useParams();
    const dispatch:AppDispatch = useDispatch();
    const { data, error, refetch } = useGetSingleProjectQuery(id);

    useEffect(()=>{
        dispatch(setFetching(true));
        if(data){
            dispatch(setFetching(false));
            dispatch(setCurrentProject({...data?.response}))
        }
        if(error){
            dispatch(setFetching(false))
            dispatch(setFetchError(true))
        }
    },[data, error])

    const retryRequest = async() => {
        dispatch(setFetching(true))
        const {data, error} = await refetch();
        if(data){
            dispatch(setFetching(false))
            dispatch(setCurrentProject({...data?.response}))
        }
        if(error){
            dispatch(setFetching(false))
            dispatch(setFetchError(true))
        }
    }

    return (
        <div>
            <div className="relative min-h-[400px] w-full">
                {   
                    isFetching 
                    ? 
                        <Loader/> 
                    :
                    isFetchError ?
                        <Retry
                            action={retryRequest}
                        />
                    :
                    isEmptyProject ?
                        <EmptyState context={"project"}/>
                    :
                        <Project/>
                }
            </div>
        </div>
    )
}

export default CurrentProject