import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { useGetSingleProjectQuery } from '../../../../../../Store/Slices/Project/ProjectApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../../Store/Store';
import { setFetchError, setFetching, setCurrentProject } from '../../../../../../Store/Slices/Project/ProjectSlice';
import { Loader } from '../../../../../UI/Loader';
import Products from '../../../../GenericSections/Products';
import SectionSeparator from '../../../../../UI/SectionSeparator';
import Footer from '../../../../../UI/Footer';
import CurrentProject from './CurrentProject';
import MoreProjects from './MoreProjects';
import Contact from '../../../../GenericSections/Contact';
import Plant from '../../../../GenericSections/Plant';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmptyState from '../../../../../UI/EmptyState';
import Retry from '../../../../../UI/Retry';

const Project:React.FC = () => {
    const { id } = useParams();
    const {isFetching, isEmptyProject, isFetchError} = useSelector((state: RootState) => state.project);
    const dispatch:AppDispatch = useDispatch();
    const {data, error, refetch} = useGetSingleProjectQuery(id);

    useEffect(()=>{
        if(isFetching){
            dispatch(setFetchError(false));
            retryRequest();
        }
    },[isFetching])

    const retryRequest = async() => {
        dispatch(setFetching(true))
        dispatch(setFetchError(false))

        const {data, error} = await refetch();
        if(data){
            dispatch(setFetching(false))
            dispatch(setCurrentProject({...data?.response}));
        }
        if(error){
            dispatch(setFetching(false))
            dispatch(setFetchError(true))
        }
    }

    return (
        <section className="w-full" data-scroll-container id="current-project-container">
            <div className="w-full mt-36 mb-24 px-[5%]" data-scroll-section>
                <Link to="/" className="text-sm flex items-center gap-2 mb-8"><ArrowBackIcon style={{fontSize: 14, color: 'black'}}/>Back to homepage</Link>
                <div className="relative min-h-[400px] w-full">
                    {   
                        isFetching 
                        ? 
                            <Loader/> 
                        :
                        isFetchError ?
                            <Retry
                                action={retryRequest}
                            />
                        :
                        isEmptyProject ?
                            <EmptyState context={"project"}/>
                        :
                            <CurrentProject/>
                    }
                </div>
            </div>
            <SectionSeparator/>
            <MoreProjects/>
            <SectionSeparator/>
            <Products/>
            <SectionSeparator/>
            <Plant/>
            <Contact/>
            <Footer/>
        </section>
    )
}

export default Project