import React, { useCallback, useEffect } from 'react'
import Header from './Sections/Header'
import Statistics from './Sections/Statistics'
import Projects from './Sections/Projects/Projects'
import Card from '../Card'
import { useGetAllProjectsQuery } from '../../../../Store/Slices/Project/ProjectApiSlice'
import { setFetchError, setFetching, setProjects } from '../../../../Store/Slices/Project/ProjectSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../Store/Store'

const Home:React.FC = () => {
    const {refetch} = useGetAllProjectsQuery({});
    const dispatch:AppDispatch = useDispatch();
    const {isFetching} = useSelector((state: RootState) => state.project);

    const reload = useCallback(async() => {
        if(isFetching){
            const {data, error} = await refetch();
            if(data){
                dispatch(setFetching(false))
                dispatch(setProjects([...data?.response]));
            }
            if(error){
                dispatch(setFetching(false))
                dispatch(setFetchError(true))
            }
        }
    },[isFetching])

    useEffect(() => {
        dispatch(setFetching(true));
    },[])

    useEffect(()=>{
        reload()
    },[reload])
    
    return (
        <Card>
            <div className="w-full">
                <Header/>
                <Statistics/>
                <Projects/>
            </div>
        </Card>
    )
}

export default Home