import React from 'react'

interface ButtonType{
    type: "submit" | "reset" | "button",
    style: string,
    action: ()=>void,
    text: string
}

const Button:React.FC<ButtonType> = ({type, style, action, text}) => {
    return (
        <button type={type} className={style + " hover:bg-hover transition-all duration-500 ease-out "} onClick={action}>{text}</button>
    )
}

export default Button