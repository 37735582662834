export const TopSquares = () => {
    return (
        <div className="border-[1px] border-white-600 w-[151px] h-[151px] relative left-[8%] top-[20%] bg-white-475">
            <div className="absolute z-10 w-[55px] h-[55px] border-[1px] border-white-600 bg-white-475 -bottom-[27.5px] -left-[27.5px] "/>
        </div>
    )
}

export const BottomSquares = () => {
    return (
        <div className="border-[1px] border-white-600 w-[104px] h-[104px] relative left-[60%] lg:left-[80%] -bottom-[40%] bg-white-475">
            <div className="absolute z-10 w-[74px] h-[74px] border-[1px] border-white-600 bg-white-475 -top-[37px] -left-[37px] "/>
        </div>
    )
}