import React from 'react'
import { Link } from 'react-router-dom'

interface ContactUsType{
    style: string,
    close: () => void
}

const ContactUs:React.FC<ContactUsType> = ({style, close}) => {
    return (
        <Link to="/contact" onClick={close} className={style + " w-full text-white-600 p-3 text-center rounded-lg"}>Contact us</Link>
    )
}

export default ContactUs