import { apiRoutes } from "../../../Config/ApiRoutes";
import { apiSlice } from "../../Api/apiSlice";

const ProjectApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createProject: builder.mutation({
            query: payload => ({
                url: apiRoutes.project.CREATE,
                method: "POST",
                body: {...payload}
            })
        }),
        editProject: builder.mutation({
            query: payload => {
                const {id, ...body} = payload
                return{
                    url: apiRoutes.project.EDIT + id,
                    method: 'PUT',
                    body: {...body}
                }
            }
        }),
        deleteProject: builder.mutation({
            query: id => ({
                url: apiRoutes.project.DELETE + id,
                method: "DELETE",
            })
        }),
        getAllProjects: builder.query({
            query: () =>({
                url: apiRoutes.project.GET + "/all"
            })
        }),
        getAllCompletedProjects: builder.query({
            query: () => ({
                url: apiRoutes.project.GET + "/completed"
            })
        }),
        getSingleProject: builder.query({
            query: (id) => ({
                url: apiRoutes.project.GET + `/find/${id}`
            })
        }),
        uploadFiles: builder.mutation({
            query: payload => ({
                url: apiRoutes.file.UPLOAD,
                method: "POST",
                body: payload
            })
        })
    })
})

export const {
    useCreateProjectMutation,
    useGetAllProjectsQuery,
    useGetAllCompletedProjectsQuery,
    useGetSingleProjectQuery,
    useDeleteProjectMutation,
    useUploadFilesMutation,
    useEditProjectMutation,
} = ProjectApiSlice