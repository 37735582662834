import {configureStore} from '@reduxjs/toolkit'
import {apiSlice} from './Api/apiSlice'
import AuthSlice from './Slices/Auth/AuthSlice'
import ResponseSlice from './Slices/Response/ResponseSlice'
import ProjectSlice from './Slices/Project/ProjectSlice'

const Store = configureStore({
    reducer:{
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: AuthSlice,
        response: ResponseSlice,
        project: ProjectSlice
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(apiSlice.middleware)
    },
    devTools:true
})

export type RootState = ReturnType<typeof Store.getState>

export type AppDispatch = typeof Store.dispatch

export default Store