import React from 'react'

interface ErrorType{
    valid: boolean,
    message: string
}

interface InputType{
    id: string,
    type: string,
    name: string,
    title: string,
    value: string,
    error: ErrorType
    placeholder: string,
    onChange: (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
}

const Input:React.FC<InputType> = ({title, type, id, name, value, error, placeholder, onChange}) => {
    const borderColor = error?.valid ? "border-red-500" : "border-white-375 ";

    return (
        <>
            {   type === "text" || type === "password" 
                ? 
                    <div className="w-full">
                        <div className="w-full flex justify-between items-center pb-1">
                            <p className='text-sm font-medium'>{title}</p>
                            <small className="text-[12px] text-red-600">{error?.message}</small>
                        </div>
                        <input 
                            title={title} 
                            type={type} 
                            id={id} 
                            name={name} 
                            value={value} 
                            placeholder={placeholder}
                            className={"border-[1.5px] w-full rounded-lg p-3 placeholder-white-495 focus:border-orange outline-orange " + borderColor}
                            onChange={onChange}
                        />
                    </div>
                :
                <div className="w-full">
                    <div className="w-full flex justify-between items-center pb-1">
                        <p className='text-sm font-medium'>{title}</p>
                        <small className="text-red-600 text-[12px]">{error?.message}</small>
                    </div>
                    <textarea 
                        title={title} 
                        name={name} 
                        id={id}
                        value={value}
                        onChange={onChange} 
                        cols={30} 
                        rows={8}
                        placeholder={placeholder}
                        className={"border-[1.5px] w-full rounded-lg p-3 placeholder-white-495 focus:border-orange outline-orange " + borderColor}
                    />
                </div>
            }
        </>
    )
}

export default Input