import React from 'react'
import Modal from '../Modal'

interface ProjectModalType{
    src: string,
    isOpen: boolean,
    close: () => void
}

const ProjectModal:React.FC<ProjectModalType> = ({src, isOpen, close}) => {
    return (
        <Modal
            isOpen={isOpen}
            close={close}
        >
            <img src={src} className="w-[90%] h-[90%] z-30 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover" alt="Project-Image"/>
        </Modal>
    )
}

export default ProjectModal