import React from 'react'

interface ControlType{
    func: (isForward: boolean) => void
}

const Control:React.FC<ControlType> = ({func}) => {
    return (
        <div className="hidden md:flex items-center gap-2 my-8">
            <span 
                onClick={()=>func(false)}
                className="w-10 h-10 rounded-full flex justify-center items-center text-center text-white-600 cursor-pointer bg-black-450"
            >
                {"<"}    
            </span>
            <span
                onClick={()=>func(true)}
                className="w-10 h-10 rounded-full flex justify-center items-center text-center text-white-600 cursor-pointer bg-black-600"
            >
                {">"}
            </span>
        </div>
    )
}

export default Control