import React, { useState, useEffect } from 'react';
import { useGetAllCompletedProjectsQuery } from './Store/Slices/Project/ProjectApiSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from './Store/Store';
import { setFetchError, setFetching, setProjects } from './Store/Slices/Project/ProjectSlice';
import { useLocation } from 'react-router-dom';
import { AdminRoutes, MainRoutes } from './Routing/Routes';
import PageStructure from './Components/Main/PageStructure';
import ResponseModal from './Components/UI/Modals/Response/ResponseModal';
import './App.css';

const AdminStructure:React.FC = () => {
  return (
    <AdminRoutes/>
  )
}

const MainStructure:React.FC = () => {
  return (
    <PageStructure>
      <MainRoutes/>
    </PageStructure>
  )
}

const App:React.FC = () => {
  const {data, error} = useGetAllCompletedProjectsQuery({});
  const {pathname} = useLocation();
  const [currentPath, setCurrentPath] = useState<string>(pathname)

  const dispatch:AppDispatch = useDispatch();

  useEffect(()=>{
    setCurrentPath(pathname)
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  },[pathname])

  useEffect(()=>{
    dispatch(setFetching(true));
    dispatch(setFetchError(false))

    if(data){
      dispatch(setFetching(false));
      dispatch(setProjects([...data?.response]))
    }

    if(error){
      dispatch(setFetching(false))
      dispatch(setFetchError(true))
    }
    
  },[data, error])

  const isAdmin = pathname.includes("admin")
  
  return (
    <div className="w-full" id="app-container" data-scroll-container>
      <ResponseModal/>

      {isAdmin ? <AdminStructure/> : <MainStructure/>}
    </div>
  )
}

export default App;
