import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppDispatch, RootState } from '../../../../../Store/Store'
import { Loader } from '../../../../UI/Loader'
import { useGetAllCompletedProjectsQuery } from '../../../../../Store/Slices/Project/ProjectApiSlice'
import { setFetchError, setFetching, setProjects } from '../../../../../Store/Slices/Project/ProjectSlice'
import { getCategoryAndYear } from '../../../../../Utils/Helper'
import EmptyState from '../../../../UI/EmptyState'
import Retry from '../../../../UI/Retry'

const Projects:React.FC = () => {
    const {isFetching, isEmpty, isFetchError, projects} = useSelector((state: RootState) => state.project);
    const dispatch:AppDispatch = useDispatch();
    const {refetch} = useGetAllCompletedProjectsQuery({});

    const retryRequest = async() => {
        dispatch(setFetching(true))
        dispatch(setFetchError(false))
        const {data, error} = await refetch();
        
        if(data){
            dispatch(setFetching(false))
            dispatch(setProjects([...data?.response]));
        }

        if(error){
            dispatch(setFetching(false))
            dispatch(setFetchError(true))
        }
    }
    return (
        <section className="w-full px-[5%] py-16" data-scroll-section>
            <h1 className="text-[32px] font-medium md:text-[74px]">Past Projects</h1>
            <p className="text-[12px] md:text-sm text-gray max-w-[600px]">
                Electrical Solutions offers a comprehensive range of services designed to meet the diverse needs of modern infrastructure.          
            </p>
            <div className="w-full relative min-h-[400px]">
                {   
                    isFetching 
                    ? 
                        <Loader/> 
                    :
                    isFetchError ?
                        <Retry
                            action={retryRequest}
                        />
                    :
                    isEmpty ?
                        <EmptyState context={"projects"}/>
                    :
                    <ul className="w-full max-w-[1000px] mt-2 mx-auto md:flex md:flex-wrap gap-[4%]">
                        {projects?.map((value, index) => {
                            return (
                                <li key={index} className="w-full md:w-[48%] mt-10">
                                    <Link to={`/projects/${value?.code}`} onClick={()=>dispatch(setFetching(true))} key={index} className="w-full">
                                        <img src={value.images.find(image => image.mainImage)?.url} className="w-full h-[270.73px] md:h-[461px] object-cover" alt="Main" />
                                        <p className="text-[20px] mt-6 w-full truncate overflow-hidden">{value?.title}</p>
                                        <small className="text-[12px] text-gray">{getCategoryAndYear(value?.category, value?.endDate)}</small>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                }
            </div>
        </section>
    )
}

export default Projects