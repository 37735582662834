import React,{ useState, useEffect } from 'react'
import DatePicker from 'react-datepicker';
import { MdCalendarMonth } from "react-icons/md";
import 'react-datepicker/dist/react-datepicker.module.css'

interface ErrorType{
    valid: boolean,
    message: string
}

interface DateFilterType{
    title:string,
    placeholder:string,
    error:ErrorType,
    value:string,
    handleChange:(date: string) => void
}

const DateFilter:React.FC<DateFilterType> = ({title, placeholder, error, value, handleChange}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [dateString, setDateString] = useState<string | null>(null)

    useEffect(() => {
        if(startDate){
            const month = startDate?.getMonth() + 1;
            const date = startDate?.getDate();
            let dateString = date < 10 ? `0${date}` : date
            let monthString = month < 10 ? `0${month}` : month

            const value = `${startDate?.getFullYear()}-${monthString}-${dateString}`
            setDateString(value)
            handleChange(value);
        }
    }, [startDate, handleChange])

    const forceClose = () => {
        setIsOpen(false)
    }

    const borderColor = error?.valid ? "border-red-500" : "border-white-400 ";

    return (
        <div className="w-full flex flex-col gap-1 relative">
            <p className='text-sm font-medium mb-1'>{title}</p>
            <div className={"flex items-center justify-start gap-2 py-3 px-2 border-[1.5px] rounded-lg w-full " + borderColor}>
                <MdCalendarMonth color='black' cursor={'pointer'} size={25} onClick={()=>setIsOpen(true)}/>
                <span className={(startDate || value) ? "text-black-600" : "text-white-495"}>{value !== "" ? value : dateString ? dateString : placeholder}</span>
            </div>
            {isOpen && 
                <div className="absolute top-full mt-4 z-20">
                <DatePicker
                    onClickOutside={forceClose}
                    onSelect={forceClose}
                    inline
                    selected={startDate} 
                    onChange={(date: Date | null) => setStartDate(date)}
                />
                </div>
            }
        </div>
    )
}

export default DateFilter