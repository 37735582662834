import React,{ useState, useEffect, ReactNode } from 'react'
import ReactDOM from 'react-dom'

interface ModalType{
    children: ReactNode,
    isOpen: boolean,
    close: () => void
}

const Modal:React.FC<ModalType> = ({children, isOpen, close}) => {
    const [modalElement] = useState(document.createElement("div"));
    useEffect(()=>{
        document.body.appendChild(modalElement);

        return ()=>{
            document.body.removeChild(modalElement)
        }
    },[modalElement])
    return isOpen ?
                ReactDOM.createPortal(
                    <div className="w-full h-screen">
                        <div onClick={close} className="w-full h-screen fixed bg-black-475 blur-bg top-0 left-0 z-20 cursor-pointer"/>
                        {children}
                    </div>,
                    modalElement
                )
            :
            null
}

export default Modal