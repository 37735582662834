import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useLoginMutation } from '../../../Store/Slices/Auth/AuthApiSlice'
import { AppDispatch } from '../../../Store/Store'
import { useDispatch } from 'react-redux'
import { constructResponse } from '../../../Store/Slices/Response/ResponseSlice'
import { ProcessingRequest } from '../../UI/Loader'
import {ValidateLoginForm as ValidateForm }from './ValidateForm'
import { setCredentials } from '../../../Store/Slices/Auth/AuthSlice'
import Card from './Card/Card'
import Input from '../../UI/Input'
import Button from '../../UI/Button'

interface FormType{
    email: string,
    password: string
}
interface ErrorType{
    valid: boolean,
    message: string
}
interface FormErrorType{
    email: ErrorType,
    password: ErrorType
    none: boolean
}

const Login:React.FC = () => {
    const [login] = useLoginMutation({});
    const dispatch:AppDispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [processing, setProcessing] = useState<boolean>(false)

    const [inputFields, setInputFields] = useState<FormType>({
        email: "",
        password: ""
    })
    
    const [inputErrors, setInputErrors] = useState<FormErrorType>({
        email: {
            valid: false,
            message: ""
        },
        password:{
            valid: false,
            message: ""
        },
        none: false
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;

        setInputFields(prev => {
            return {...prev, [name]:value}
        })
    }

    const handleSubmit = async(e:React.FormEvent) => {
        e.preventDefault();

        const errors:FormErrorType = ValidateForm(inputFields);
        setInputErrors(errors);

        if(errors.none){
            setProcessing(true);

            const {data, error} = await login(inputFields)
            if(data){
                const user = {
                    user: {
                        firstName: data?.response?.firstName,
                        lastName: data?.response?.lastName,
                        email: data?.response?.email,
                    },
                    token: data?.response?.token,
                    isLoggedIn: true
                }

                setProcessing(false)
                
                dispatch(setCredentials(user))

                setInputFields({
                    email: "",
                    password: ""
                })
                
                navigate("/admin/home", {state: location , replace:true})
            }
            if(error){
                setProcessing(false)

                const newResponse = {
                    valid: true,
                    message: "Invalid credentials.",
                    type:"failure"
                }

                dispatch(constructResponse(newResponse))
            }
        }
    }

    return (
        <Card>
            <div className="w-full bg-white-600 rounded-lg relative">
                
                {processing && <ProcessingRequest style={"top-0 right-0"}/>}

                <h2 className="text-[32px] font-medium mb-8">Login</h2>
                <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                    <Input 
                        title="Email" 
                        type="text" 
                        id="email" 
                        name="email" 
                        placeholder="example@gmail.com"
                        value={inputFields.email} 
                        error={inputErrors.email}
                        onChange={handleChange}
                    />
                    <Input 
                        title="Password" 
                        type="password" 
                        id="password" 
                        name="password" 
                        placeholder="********"
                        value={inputFields.password} 
                        error={inputErrors.password}
                        onChange={handleChange}
                    />
                    <Link to="/admin/verify-email" className="text-orange font-medium text-right text-sm">Forgot password?</Link>
                    <Button
                        type='submit'
                        style={"bg-orange w-full mx-auto mb-5 cursor-pointer text-white-600 block py-3 rounded-md"}
                        text="Login"
                        action={()=>{}}
                    />
                </form>
                <p className="text-center">Don't have an account? <span><Link to="/admin/register" className="text-black-600 font-semibold pb-1 border-b-black-600 border-b-[1.5px]">Create one</Link></span></p>
            </div>
        </Card>
    )
}

export default Login