import React from 'react'
import { Link } from 'react-router-dom'
import BulletPoint from '../../../UI/BulletPoint'
import plant from '../../../../Assets/Images/Landing/pipeline.png'
import factory from '../../../../Assets/Images/Landing/automotive-factory.png'

const MechanicalSolutions:React.FC = () => {
    return (
        <section className="w-full py-16 px-[5%] light-section" data-scroll-section>
            <BulletPoint
                style={"md:justify-center mt-8"}
                text={"Our core services"}
            />
            <h1 className="text-[32px] mt-3 md:mt-0 md:text-[74px] md:text-center">Mechanical Solutions</h1>
            <p className="text-sm text-gray md:text-center md:max-w-[480px] mx-auto">
                Mechanical Solutions provides a wide array of services tailored to ensure optimal performance and comfort in your projects.            
            </p>
            <div className="md:grid md:grid-cols-[2fr_1fr] w-full gap-5 mt-8">
                <div className="w-full h-full">
                    <img src={plant} alt="Plant" className="w-full md:h-full object-cover"/>
                </div>
                <div className="w-full h-full">
                    <img src={factory} alt="Factory" className="w-full mt-5"/>
                    <p className="text-sm text-gray mt-6">
                        Our services include vertical transportation systems, such as lifts and escalators, designed for seamless integration and optimal operational efficiency.
                    </p>
                    <ul className="w-full mt-8 pb-8">
                        <li className="text-gray text-[12px] py-3 flex items-center gap-3 border-t-gray border-t-[1px]">1<span className="text-gray text-[18px]">Air-Conditioning and Ventilation System.</span></li>
                        <li className="text-gray text-[12px] py-3 flex items-center gap-3 border-t-gray border-t-[1px]">2<span className="text-gray text-[18px]">Refrigeration, Cold Rooms and Storage System</span></li>
                        <li className="text-gray text-[12px] py-3 flex items-center gap-3 border-t-gray border-t-[1px]">3<span className="text-gray text-[18px]">Plumbing System</span></li>
                    </ul>
                    <Link to="/services" className="text-blue text-sm pb-[0.5px] border-b-[1px] border-blue">View more services</Link>
                </div>
            </div>
        </section>
    )
}

export default MechanicalSolutions