import React from 'react'
import Button from '../../UI/Button'
import contact from '../../../Assets/Images/Landing/contact.png'
import { useNavigate } from 'react-router-dom'

const Contact:React.FC = () => {
    const navigate = useNavigate()
    return (
        <section className="w-full mx-auto px-[5%] py-16 md:flex gap-12 items-end light-section" data-scroll-section>
            <img src={contact} alt="Engineer" className="w-full object-cover md:max-w-[459px] md:h-[505px]" />
            <div>
                <h1 className="text-[32px] font-medium md:text-[74px] mt-14 md:mt-0 leading-none">Ready to bring your project to life?</h1>
                <p className="text-sm max-w-[512.83px] mt-4 md:mt-10">
                    Contact us today to get started! Our expert team is here to provide innovative and reliable solutions tailored to your needs. Let’s build something great together.
                </p>
                <Button
                    type="button"
                    action={()=>navigate("/contact")}
                    text="Contact Us"
                    style="w-full md:max-w-[181px] bg-orange px-2 py-[17px] mx-auto mt-8 rounded-lg text-white-600 cursor-pointer"
                />
            </div>
        </section>
    )
}

export default Contact