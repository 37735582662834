import React from 'react'
import OverviewWrapper from '../../../UI/OverviewWrapper'
import overview from '../../../../Assets/Images/Services/overview.png'

const OverView:React.FC = () => {
    return (
        <OverviewWrapper
            style={"w-full bg-no-repeat bg-cover px-[5%] pt-5 pb-36 responsive-services-overview-bg" }
            imageUrl={overview}
        >
            <div className="w-full flex flex-col gap-8 mt-96">
                <h1 className="text-[48px] font-medium leading-none md:text-7xl text-white-600">Services we offer</h1>
                <p className="text-[16px] max-w-[584.64px] text-white-500">
                    We offer a wide range of engineering services designed to meet the needs of various industries and building types.
                </p>
            </div>
        </OverviewWrapper>
    )
}

export default OverView