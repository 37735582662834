import React from 'react'
import { RootState } from '../../../../../Store/Store'
import { useSelector } from 'react-redux'

const Statistics:React.FC = () => {
    const {projects} = useSelector((state:RootState) => state.project);
    const completedProjects = projects?.filter(project => project.status.toLowerCase() === "completed")
    const ongoingProjects = projects?.filter(project => project.status.toLowerCase() === "ongoing")

    return (
        <div className="w-full flex flex-col md:grid grid-cols-3 py-4 border-[1px] mt-8 rounded-lg border-white-425">
            <div className="flex flex-col gap-2 px-4 items-center md:items-start justify-center md:justify-start" >
                <h4 className="text-white-325 text-[16px] font-medium">Total Projects</h4>
                <h2 className="font-medium text-[32px]">{projects?.length || 0}</h2>
            </div>
            <div className="flex flex-col gap-2 items-center md:items-start justify-center md:justify-start px-4 py-4 md:py-0 md:border-t-transparent border-t-white-435 border-dashed border-t-[1px] border-b-[1px] border-b-white-435 md:border-b-transparent md:border-l-white-435 md:border-l-[1px] md:border-r-white-435 md:border-r-[1px]">
                <h4 className="text-white-325 text-[16px] font-medium">Completed Projects</h4>
                <h2 className="font-medium text-[32px]">{completedProjects?.length || 0}</h2>
            </div>
            <div className="flex flex-col gap-2 py-2 md:py-0 px-4 items-center md:items-start justify-center md:justify-start">
                <h4 className="text-white-325 text-[16px] font-medium">Ongoing Projects</h4>
                <h2 className="font-medium text-[32px]">{ongoingProjects?.length || 0}</h2>
            </div>
        </div>
    )
}

export default Statistics