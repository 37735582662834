import React from 'react'

const Logout:React.FC = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.20117 21.6562H5.20117C4.67074 21.6562 4.16203 21.4455 3.78696 21.0705C3.41189 20.6954 3.20117 20.1867 3.20117 19.6562V5.65625C3.20117 5.12582 3.41189 4.61711 3.78696 4.24204C4.16203 3.86696 4.67074 3.65625 5.20117 3.65625H9.20117" stroke="#D70000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.2012 17.6562L21.2012 12.6562L16.2012 7.65625" stroke="#D70000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.2012 12.6562H9.20117" stroke="#D70000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Logout