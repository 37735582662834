import React from 'react'
import logo from '../../Assets/Icons/logo.svg'

interface RetryMethodType{
    action: () => void
}

const Retry:React.FC<RetryMethodType> = ({action}) => {
    return (
        <div className="w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center gap-3 text-center items-center">
            <img src={logo} className="w-8 h-8" alt="Logo" />
            <p>Something went wrong...</p>
            <button type="button" className="text-white-600 text-12px rounded-lg w-full max-w-[120px] hover:bg-hover transition-all duration-500 ease-out p-2 bg-orange" onClick={action}>Retry</button>
        </div>
    )
}

export default Retry