import React from 'react'
import Modal from '../Modal'
import Button from '../../Button'
import { CheckBox, Close, ErrorOutline } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../../../../Store/Store'
import { constructResponse } from '../../../../Store/Slices/Response/ResponseSlice'

interface ResponseModalType{
    type: string,
    valid: boolean,
    message: string,
    close: () => void
}

const SuccessModal:React.FC<ResponseModalType> = ({close, message}) => {
    return (
        <div className="w-[90%] max-w-[500px] bg-white-600 z-50 rounded-md p-6 top-1/2 left-1/2 fixed -translate-x-1/2 -translate-y-1/2">
            <div className="flex flex-col justify-center items-center">
                <div className="p-8 rounded-full bg-orange ">
                    <CheckBox fontSize='medium' style={{ color: 'white' }}/>
                </div>
                <p className="mt-6 text-center text-black-500 font-medium">{message}</p>
                <Button
                    text="Close"
                    style={" mt-6 p-4 text-sm text-white-600 rounded-lg bg-orange w-full"}
                    action={close}
                    type="button"
                />
            </div>
        </div>
    )
}

const FailModal:React.FC<ResponseModalType> = ({close, message}) => {
    return (
        <div className="w-[90%] max-w-[500px] bg-white-600 z-50 rounded-md p-6 top-1/2 left-1/2 fixed -translate-x-1/2 -translate-y-1/2">
            <div className="flex flex-col justify-center items-center">
                <div className="p-8 rounded-full bg-red-500 ">
                    <Close fontSize='large' style={{ color: 'white' }}/>
                </div>
                <p className="mt-6 text-center text-black-500 font-medium">{message}</p>
                <Button
                    text="Close"
                    style={" mt-6 p-4 text-sm text-white-600 rounded-lg bg-orange w-full"}
                    action={close}
                    type="button"
                />
            </div>
        </div>
    )
}

const WarnModal:React.FC<ResponseModalType> = ({close, message}) => {
    return (
        <div className="w-[90%] max-w-[500px] bg-white-600 z-50 rounded-md p-6 top-1/2 left-1/2 fixed -translate-x-1/2 -translate-y-1/2">
            <div className="flex flex-col justify-center items-center">
                <div className="p-8 rounded-full bg-orange ">
                    <ErrorOutline fontSize='large' style={{ color: 'white' }}/>
                </div>
                <p className="mt-6 text-center text-black-500 font-medium">{message}</p>
                <Button
                    text="Close"
                    style={" mt-6 p-4 text-sm text-white-600 rounded-lg bg-orange w-full"}
                    action={close}
                    type="button"
                />
            </div>
        </div>
    )
}

const getResponseModal = (params:ResponseModalType) => {
    const {type, valid, close, message} = params;

    switch(type){
        case "success":
            return <SuccessModal type={type} valid={valid} close={close} message={message}/>
        case "failure":
            return <FailModal type={type} valid={valid} close={close} message={message}/>
        case "warning":
            return <WarnModal type={type} valid={valid} close={close} message={message}/>
        default:
            return;

    }
}

const ResponseModal:React.FC= () => {
    const {valid, message, type} = useSelector((state:RootState) => state.response.response)

    const dispatch:AppDispatch = useDispatch();

    const clearResponse = () => {
        const newResponse = {
            valid: false,
            type: "",
            message: ""
        }
        dispatch(constructResponse(newResponse))
    }

    const params = {
        valid,
        message,
        type,
        close: clearResponse
    }


    return (
        <>
            {valid && 
                <Modal
                    isOpen={valid}
                    close={clearResponse}
                >
                    {getResponseModal(params)}
                </Modal>
            }
        </>
    )
}

export default ResponseModal