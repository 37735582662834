import React from 'react'
import Form from './Form/Form'
import overview from '../../../../../Assets/Images/Contact/overview.png'
import OverviewWrapper from '../../../../UI/OverviewWrapper'

const OverView:React.FC = () => {
    return (
        <OverviewWrapper
            style={"w-full bg-no-repeat bg-cover px-[5%] pt-12 responsive-projects-overview-bg" }
            imageUrl={overview}
        >
            <div className="lg:grid lg:grid-cols-2 lg:items-end lg:gap-5 py-16">
                <div className="w-full flex flex-col gap-8 mt-96 lg:mt-0">
                    <h1 className="text-[48px] font-medium md:text-7xl text-white-600">Contact us</h1>
                    <p className="text-[16px] max-w-[500px] text-white-500">
                        Our team is ready to assist with any inquiries you may have, from project consultations to service details. Get in touch and let’s start building something great together.
                    </p>
                </div>
                <div className="hidden lg:block">
                    <Form/>
                </div>
            </div>
        </OverviewWrapper>
    )
}

export default OverView