import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppDispatch, RootState } from '../../../../Store/Store'
import { Loader } from '../../../UI/Loader'
import { ProjectType, setFetchError, setFetching, setProjects } from '../../../../Store/Slices/Project/ProjectSlice'
import { getCategoryAndYear } from '../../../../Utils/Helper'
import { useGetAllCompletedProjectsQuery } from '../../../../Store/Slices/Project/ProjectApiSlice'
import EmptyState from '../../../UI/EmptyState'
import Retry from '../../../UI/Retry'

const Projects:React.FC = () => {
    const {isFetching, isEmpty, projects, isFetchError} = useSelector((state: RootState) => state.project);
    const [filteredProjects, setFilteredProjects] = useState<ProjectType[]>([]);
    const dispatch:AppDispatch = useDispatch();
    const {refetch} = useGetAllCompletedProjectsQuery({});

    const retryRequest = async() => {
        dispatch(setFetching(true))
        dispatch(setFetchError(false))
        const {data, error} = await refetch();
        if(data){
            dispatch(setFetching(false))
            dispatch(setProjects([...data?.response]));
        }
        if(error){
            dispatch(setFetching(false))
            dispatch(setFetchError(true))
        }
    }

    
    useEffect(()=>{
        if(projects){
            const newProjects = projects.filter(project => {
                const projectIndex = projects.indexOf(project)
                return projectIndex >= 0 && projectIndex <=2
            })
            setFilteredProjects(newProjects)
        }
    },[projects])
    
    return (
        <section className="py-16 px-[5%] light-section" data-scroll-section>
            <h1 className="text-[32px] md:text-[74px] md:text-center">Our Past Projects</h1>
            <p className="text-sm text-gray md:text-center max-w-[599px] mx-auto">
                Explore our portfolio of successfully completed projects, showcasing our expertise in delivering high-quality engineering solutions.         
            </p>
            <div className="relative min-h-[400px] w-full">
                {   isFetching 
                    ? 
                        <Loader/> 
                    :
                    isFetchError ?
                        <Retry
                            action={retryRequest}
                        />
                    :
                    isEmpty ?
                        <EmptyState context={"projects"}/>
                    :
                        <ul className="flex flex-col gap-8 lg:grid lg:grid-cols-3 lg:gap-5 mt-14">
                            {filteredProjects.map((value, index) => {
                                return (
                                    <li key={index} className="w-full" >
                                        <img src={value.images.find(image => image.mainImage)?.url} className="w-full h-[475px] object-cover" alt="" />
                                        <p className="text-[20px] mt-6 w-full truncate overflow-hidden">{value?.title}</p>
                                        <small className="text-[12px] text-gray">{getCategoryAndYear(value?.category, value?.endDate)}</small>
                                    </li>
                                )
                            })}
                        </ul>
                }
            </div>
            
            {!isEmpty && <Link 
                to="/projects"
                className="p-4 my-12 text-center block w-full md:max-w-[200px] mx-auto bg-orange text-white-600 rounded-lg  hover:bg-hover transition-all duration-500 ease-out"
            >
                View more
            </Link>}
        </section> 
    )
}

export default Projects