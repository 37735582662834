import React from 'react'
import { VALUES } from '../../../../Utils/Constants'

const CoreValues:React.FC = () => {
    return (
        <section className="w-full px-[5%] py-16" data-scroll-section>
            <h1 className="text-[32px] font-medium md:text-[74px]">Our Core Values</h1>
            <p className="text-[12px] md:text-sm text-gray max-w-[600px]">
                Understand the principles that guide our operations and define our commitment to excellence, innovation, and ethical practices.        
            </p>
            <ul className="w-full flex flex-wrap gap-5 mt-12">
                {VALUES.map((value, index) => {
                    return (
                        <li key={index} className="w-full max-w-[424px] flex-col relative p-6 bg-black-500 md:h-[475px]">
                            <h3 className="text-[24px] md:text-[32px] text-white-600">{value.value}</h3>
                            <p className="text-[15px] mt-12 md:0 md:absolute bottom-6 left-6 right-6 text-white-500">{value.description}</p>
                        </li>
                    )
                })}
            </ul>
        </section>
    )
}

export default CoreValues