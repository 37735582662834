import { createSlice } from "@reduxjs/toolkit";

interface AuthSliceType{
    user: any,
    token: string | null,
    isLoggedIn: boolean | string 
}

const initialState:AuthSliceType = {
    user: null,
    token: null,
    isLoggedIn: false
}

const AuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const {user, token, isLoggedIn} = action.payload;
            state.token = token;
            state.user = user;
            state.isLoggedIn = isLoggedIn
        },
        logOut: (state) => {
            state.user = null;
            state.token = null;
            state.isLoggedIn = "Logged-Out";
        }
    }
})

export const {
    setCredentials,
    logOut
} = AuthSlice.actions;

export default AuthSlice.reducer;