import React from 'react'
import { Link } from 'react-router-dom'
import BulletPoint from '../../../UI/BulletPoint'
import plant from '../../../../Assets/Images/Landing/powerplant.png'
import circuit from '../../../../Assets/Images/Landing/circuit.png'

const ElectricalSolutions:React.FC = () => {
    return (
        <section className="w-full py-16 px-[5%] light-section" data-scroll-section>
            <BulletPoint
                style={"md:justify-center mt-8"}
                text={"Our core services"}
            />
            <h1 className="text-[32px] mt-3 md:mt-0 md:text-[74px] md:text-center">Electrical Solutions</h1>
            <p className="text-sm text-gray md:text-center md:max-w-[480px] mx-auto">
                Electrical Solutions offers a comprehensive range of services designed to meet the diverse needs of modern infrastructure.
            </p>
            <div className="flex flex-col-reverse md:grid md:grid-cols-[1fr_2fr] w-full gap-5 mt-8">
                <div className="w-full h-full">
                    <img src={circuit} alt="Circuit" className="w-full"/>
                    <p className="text-sm text-gray mt-6">
                        Our expertise ensures safe and efficient power distribution, advanced lighting systems, and reliable safety measures, including fire detection and CCTV installations.
                    </p>
                    <ul className="w-full mt-8 pb-8">
                        <li className="text-gray text-[12px] py-3 flex items-center gap-3 border-t-gray border-t-[1px]">1<span className="text-gray text-[18px] ">Lighting and Power Distribution.</span></li>
                        <li className="text-gray text-[12px] py-3 flex items-center gap-3 border-t-gray border-t-[1px]">2<span className="text-gray text-[18px]">Telephone Systems.</span></li>
                        <li className="text-gray text-[12px] py-3 flex items-center gap-3 border-t-gray border-t-[1px]">3<span className="text-gray text-[18px]">Fire detection and Alarm System.</span></li>
                    </ul>
                    <Link to="/services" className="text-blue text-sm pb-[0.5px] border-b-[1px] border-blue">View more services</Link>
                </div>
                <div className="w-full h-full">
                    <img src={plant} alt="Plant" className="w-full md:h-full object-cover"/>
                </div>
                
            </div>
        </section>
    )
}

export default ElectricalSolutions