import React, { useState, useEffect, ReactNode } from 'react';

interface OverviewBgType{
    imageUrl: string,
    style: string,
    children: ReactNode
}

const OverviewWrapper:React.FC<OverviewBgType> = ({ imageUrl, style, children }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => setIsLoaded(true);
    }, [imageUrl]);

    return (
        <section
            className={`${isLoaded ? '' : 'bg-black-600 transition-all duration-500 ease-out'} ${style}`}
            style={{
                backgroundImage: `${isLoaded ? `url(${imageUrl})` : ''}`,
            }}
        >
            {children}
        </section>
    );
};

export default OverviewWrapper;
