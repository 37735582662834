import React from 'react'
import About from '../GenericSections/About'
import SectionSeparator from '../../UI/SectionSeparator'
import Products from '../GenericSections/Products'
import ProjectsList from './Sections/Projects/Projects'
import OverView from './Sections/OverView'
import Footer from '../../UI/Footer'
import Contact from '../GenericSections/Contact'
import Plant from '../GenericSections/Plant'

const Projects:React.FC = () => {
    return (
        <div className="w-full" id="projects-container" data-scroll-container>
            <OverView/>
            <About/>
            <ProjectsList/>
            <SectionSeparator/>
            <Products/>
            <SectionSeparator/>
            <Plant/>
            <Contact/>
            <Footer/>
        </div>
    )
}

export default Projects