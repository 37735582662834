import React, { ReactNode } from 'react'
import Navbar from '../UI/Navbar'

const PageStructure:React.FC<{children: ReactNode}> = ({children}) => {
    return (
        <div>
            <Navbar/>
            <>
                {children}
            </>
        </div>
    )
}

export default PageStructure