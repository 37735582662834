import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useRegisterMutation } from '../../../Store/Slices/Auth/AuthApiSlice'
import { AppDispatch } from '../../../Store/Store'
import { useDispatch } from 'react-redux'
import { constructResponse } from '../../../Store/Slices/Response/ResponseSlice'
import { ProcessingRequest } from '../../UI/Loader'
import Card from './Card/Card'
import Input from '../../UI/Input'
import Button from '../../UI/Button'
import {ValidateRegistrationForm as ValidateForm} from './ValidateForm'

interface FormType{
    firstName: string,
    lastName: string,
    email: string,
    password: string
}
interface ErrorType{
    valid: boolean,
    message: string
}
interface FormErrorType{
    firstName: ErrorType,
    lastName: ErrorType,
    email: ErrorType,
    password: ErrorType
    none: boolean
}

const Register:React.FC = () => {
    const [register] = useRegisterMutation({});
    const dispatch:AppDispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    
    const [processing, setProcessing] = useState<boolean>(false)

    const [inputFields, setInputFields] = useState<FormType>({
        firstName: "",
        lastName: "",
        email: "",
        password: ""
    })
    
    const [inputErrors, setInputErrors] = useState<FormErrorType>({
        firstName: {
            valid: false,
            message: ""
        },
        lastName: {
            valid: false,
            message: ""
        },
        email: {
            valid: false,
            message: ""
        },
        password:{
            valid: false,
            message: ""
        },
        none: false
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;

        setInputFields(prev => {
            return {...prev, [name]:value}
        })
    }

    const handleSubmit = async(e:React.FormEvent) => {
        e.preventDefault();

        const errors:FormErrorType = ValidateForm(inputFields);
        setInputErrors(errors);

        if(errors.none){
            setProcessing(true);

            const {data, error} = await register(inputFields);

            let newResponse = {
                valid: true,
                message: "",
                type:""
            };

            if(data){
                setProcessing(false);                
                newResponse.message = data?.response?.message
                newResponse.type = "success"

                setInputFields({
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: ""
                })

                navigate("/admin/login", {state: location , replace:true})
            }
            if(error){
                setProcessing(false)
                newResponse.message = "User already exists"
                newResponse.type= "failure"
            }

            dispatch(constructResponse(newResponse))
        }
    }

    return (
        <Card>
            <div className="w-full bg-white-600 rounded-lg relative">
                
                {processing && <ProcessingRequest style={"top-0 right-0"}/>}

                <h2 className="text-[32px] font-medium mb-8">Create account</h2>
                <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                    <Input 
                        title="First name" 
                        type="text" 
                        id="firstName" 
                        name="firstName" 
                        placeholder="First name"
                        value={inputFields.firstName} 
                        error={inputErrors.firstName}
                        onChange={handleChange}
                    />
                    <Input 
                        title="Last-Name" 
                        type="text" 
                        id="lastName" 
                        name="lastName" 
                        placeholder="Last name"
                        error={inputErrors.lastName}
                        value={inputFields.lastName} 
                        onChange={handleChange}
                    />
                    <Input 
                        title="Email" 
                        type="text" 
                        id="email" 
                        name="email" 
                        placeholder="example@gmail.com"
                        value={inputFields.email} 
                        error={inputErrors.email}
                        onChange={handleChange}
                    />
                    <Input 
                        title="Password" 
                        type="password" 
                        id="password" 
                        name="password" 
                        placeholder="********"
                        value={inputFields.password} 
                        error={inputErrors.password}
                        onChange={handleChange}
                    />
                    <Button
                        type='submit'
                        style={"bg-orange w-full mx-auto mb-5 cursor-pointer text-white-600 block py-3 rounded-md"}
                        text="Create account"
                        action={()=>{}}
                    />
                </form>
                <p className="text-center">Already have an account? <span><Link to="/admin/login" className="text-black-600 font-semibold pb-1 border-b-black-600 border-b-[1.5px]">Login</Link></span></p>
            </div>
        </Card>
    )
}

export default Register