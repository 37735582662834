import React from 'react'

interface StatusType{
    img: string | undefined,
    name: string,
    size: string,
    processing: boolean
}

const Status:React.FC<StatusType> = ({img, name, size, processing}) => {
    return (
        <li className="w-full p-2 rounded-lg flex items-center gap-2 border-[1px] border-white-425 ">
            <img src={img} className="w-[30px] h-[30px]" alt="Type" />
            <div className="w-full flex flex-col gap-2">
                <div className="flex w-full justify-between items-center">
                    <p className=" w-3/4 text-[11px] truncate overflow-hidden">{name}</p>
                    <p className="text-[11px]">{size}</p>
                </div>
                <div className="w-full">
                    {processing ? <div className=" w-2 h-2 rounded-full border-t-orange border-r-orange border-l-orange border-b-black-600 border-[1.5px] animate-spin"/> : <div className=" w-full h-1 bg-orange rounded-md"/>}
                </div>
            </div>
        </li>
    )
}

export default Status