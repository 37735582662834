import React from 'react'
import Footer from '../../UI/Footer'
import OverView from './Sections/Overview/OverView'
import About from './Sections/About'
import Plant from '../GenericSections/Plant'
import ContactUs from '../GenericSections/Contact'
import AboutUs from '../GenericSections/About'
import Form from './Sections/Overview/Form/Form'

const Contact:React.FC = () => {
    return (
        <div className="w-full" id="app-container" data-scroll-container>
            <OverView/>
            <div className="lg:hidden" data-scroll-section>
                <Form/>
            </div>
            <div className="hidden lg:block" data-scroll-section>
                <About/>
            </div>
            <div className="lg:hidden" data-scroll-section>
                <AboutUs/>
            </div>
            <Plant/>
            <ContactUs/>
            <Footer/>
        </div>
    )
}

export default Contact