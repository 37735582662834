import React from 'react'
import SearchBar from '../../../../UI/SearchBar'

const Header:React.FC = () => {
    return (
        <header className="w-full flex flex-col gap-6 lg:flex-row lg:gap-0 items-start">
            <div className="w-full">
                <h1 className="text-[32px] font-bold">Home</h1>
                <p className="text-[16px] text-white-325">View and monitor all of your projects here</p>
            </div>
            <div className="w-full">
                <SearchBar/>
            </div>
        </header>
    )
}

export default Header