import { createSlice } from "@reduxjs/toolkit";

export interface ProjectType{
    title: string,
    subTitle: string,
    description: string,
    category: string,
    startDate: string,
    status: string,
    endDate: string,
    client: string,
    code: string,
    images: any[]
}  

interface ProjectSliceType{
    isFetching: boolean,
    isFiltering: boolean,
    isFetchError: boolean,
    isEmpty: boolean,
    isEmptyProject: boolean, 
    projects: ProjectType[] | null,
    filteredProjects: ProjectType[] | null,
    currentProject: ProjectType
}

const initialState:ProjectSliceType = {
    isFetching: true,
    isFiltering: false,
    isFetchError: false,
    isEmpty: true,
    isEmptyProject: true,
    projects: null,
    filteredProjects: null,
    currentProject: {
        title: "",
        subTitle: "",
        description: "",
        category: "",
        startDate: "",
        endDate: "",
        client: "",
        status: "",
        code: "",
        images: []
    }
}

const ProjectSlice = createSlice({
    name: "project",
    initialState,
    reducers:{
        setFetching: (state, action) => {
            state.isFetching = action.payload
        },
        setFetchError: (state, action) => {
            state.isFetchError = action.payload
        },
        setProjects: (state, action) => {
            state.projects = [...action.payload]
            state.filteredProjects = [...action.payload]
            state.isEmpty = action.payload.length === 0 
        },
        setCurrentProject: (state, action) => {
            state.isEmptyProject = action.payload === null
            state.currentProject = action.payload
        },
        searchProject: (state, action) => {
            if(action.payload){
                state.isFiltering = true
                const filteredProjects = state.projects?.filter(project => project.title.includes(action.payload))
                
                if(filteredProjects){
                    console.log(filteredProjects)
                    state.filteredProjects = [...filteredProjects]
                    state.isFiltering = false
                }
            }
            else{
                state.filteredProjects = state.projects
            }
            
        }
    }
})

export const {
    setFetching,
    setFetchError,
    setProjects,
    searchProject,
    setCurrentProject
} = ProjectSlice.actions;

export default ProjectSlice.reducer;