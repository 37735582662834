import React from 'react'
import logo from '../../Assets/Icons/logo.svg'
import { Link } from 'react-router-dom'

const Footer:React.FC = () => {
    return (
        <section className="w-full dark-section" data-scroll-section>
            <footer className="bg-black-600 lg:grid lg:grid-cols-[1fr_2fr] gap-20 py-16 px-[5%]">
                <div className="mb-12">
                    <img src={logo} className="w-24" alt="Logo"/>
                    <p className="text-sm text-white-500 mt-8 max-w-[452px] leading-6">
                        At Omecon Engineering Limited, we deliver engineering excellence in building services with skilled professionals and innovative solutions.
                    </p>
                </div>
                <div className="w-full mt-12 flex flex-wrap lg:flex-nowrap lg:justify-between">
                    <div className="text-[16px] w-1/2">
                        <p className="text-white-600">All Pages</p>
                        <ul className="text-white-500 mt-8 flex flex-col gap-5 text-sm">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/services">Services</Link></li>
                            <li><Link to="/projects">Projects</Link></li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="text-[16px] w-1/2">
                        <p className="text-white-600">Contact</p>
                        <ul className="text-white-500 mt-8 flex flex-col gap-5 text-wrap break-words text-sm">
                            <li>+234 803 077 6930</li>
                            <li>+234 802 309 3520</li>
                            <li>omeconintegratedservice@gmail.com</li>
                        </ul>
                    </div>
                    <div className="text-[16px] w-1/2 mt-12 lg:mt-0">
                        <p className="text-white-600">Address</p>
                        <ul className="text-white-500 mt-8 flex flex-col gap-5 text-wrap break-words text-sm">
                            <li>26A sehinde Callisto Crescent Apapa Oshodi, Lagos, Nigeria</li>
                        </ul>
                    </div>
                </div>
            </footer>
        </section>
    )
}

export default Footer