import React from 'react'

const About:React.FC = () => {
    return (
        <section className="bg-black-600 w-full px-[5%] py-14" id="about-us" data-scroll-section>
            <div className='w-full max-w-[1200px] mx-auto flex justify-between items-start border-b-white-600 border-b-[1px] pb-10'>
                <div>
                    <h4 className="text-white-600 text-[28px]">Phone</h4>
                    <p className="text-white-300 text-[15px] mt-5">+234 803 077 6930</p>
                    <p className="text-white-300 text-[15px] mt-5">+234 802 309 3520</p>
                </div>
                <div>
                    <h4 className="text-white-600 text-[28px]">Email</h4>
                    <p className="text-white-300 text-[15px] mt-5">omeconintegratedservice@gmail.com</p>
                </div>
                <div>
                    <h4 className="text-white-600 text-[28px]">Head Office</h4>
                    <p className="text-white-300 text-[15px] mt-5">26A sehinde Callisto Crescent Apapa Oshodi, Lagos, Nigeria</p>
                </div>
            </div>
        </section>
    )
}

export default About