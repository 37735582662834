import React from 'react'
import Products from '../GenericSections/Products'
import SectionSeparator from '../../UI/SectionSeparator'
import About from '../GenericSections/About'
import OurServices from './Sections/Services'
import OverView from './Sections/OverView'
import Footer from '../../UI/Footer'
import Contact from '../GenericSections/Contact'
import Plant from '../GenericSections/Plant'

const Services:React.FC = () => {
    return (
        <div className="w-full" id="app-container" data-scroll-container>
            <OverView/>
            <About/>
            <OurServices/>
            <SectionSeparator/>
            <Products/>
            <SectionSeparator/>
            <Plant/>
            <Contact/>
            <Footer/>
        </div>
    )
}

export default Services