import React, { useState, useEffect } from 'react'
import { NavLink as Link, useLocation, useNavigate } from 'react-router-dom'
import { useLogoutMutation } from '../../../Store/Slices/Auth/AuthApiSlice'
import Home from './Icons/Home'
import Add from './Icons/Add'
import Logout from './Icons/Logout'
import authLogo from '../../../Assets/Icons/auth-logo.svg'
import logo from '../../../Assets/Icons/logo.svg'
import LogoutModal from '../Modals/Logout/LogoutModal'
import { RootState, AppDispatch } from '../../../Store/Store'
import { useSelector, useDispatch } from 'react-redux'
import { logOut } from '../../../Store/Slices/Auth/AuthSlice'

interface NavLinkType{
    text: string,
    Icon: React.ComponentType<{color: string}>,
    to: string,
    isInView: boolean
}

const NavLink:React.FC<NavLinkType> = ({to, text, Icon, isInView}) => {
    const {pathname} = useLocation();

    const activeClass = "bg-side-bar-active rounded-lg text-black-600";
    const inActiveClass = "text-black-475";
    const defaultClass = "w-full py-2 px-4 flex items-center justify-center lg:justify-start gap-2 font-semibold ";
    const inViewClass = " w-full py-2 px-4 flex items-center justify-center"

    const isActive = pathname.includes(to);

    const className = (isActive && isInView) ? (activeClass + inViewClass) : (!isActive && isInView) ? (inActiveClass + inViewClass) : defaultClass + (isActive ? activeClass : inActiveClass)

    return(
        <Link to={to} className={className}>
            <Icon color={isActive ? "black" : "#777676"}/>    
            <span className={ isInView ? "hidden" : "hidden lg:flex"}>{text}</span>
        </Link>
    )
}

const SideBar:React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [isInView, setIsInView] = useState<boolean>(false)
    const dispatch:AppDispatch = useDispatch()
    const [logoutFnc] = useLogoutMutation({});
    const [processing, setProcessing] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const {projects} = useSelector((state: RootState) => state.project)

    useEffect(()=>{
        if(location.pathname.includes("view")) setIsInView(true)
        else setIsInView(false)
    },[location.pathname])

    const logout = async() => {
        setProcessing(true)
        const {data, error} = await logoutFnc({});

        if(data || error){
            dispatch(logOut())
            setProcessing(false)
            setOpen(false)
            
            navigate("/admin/login", {state: location, replace: true})
        }
    }



    return (
        <div className="w-full bg-side-bar h-full p-4 flex flex-col justify-between">
            <div className="w-full">
                <img src={logo} className={isInView ? "w-10 mx-auto" : "w-10 mx-auto lg:hidden"} alt="Logo"/>
                <img src={authLogo} className={isInView ? "hidden" : "hidden lg:flex lg-w-[121.2px] lg-h-[45.26px]"} alt="Logo"/>
                <nav className="border-y-[1px] border-dashed my-6 border-y-white-325 py-6 flex flex-col gap-4">
                    <NavLink 
                        to={"/admin/home"}
                        Icon = {Home}
                        isInView={isInView}
                        text="Home"
                    />
                    <NavLink 
                        to={"/admin/projects"}
                        Icon = {Add}
                        isInView={isInView}
                        text="Add Projects"
                    />
                </nav>
                <div className={isInView ? "hidden" : "hidden max-w-[200px] md:flex md:flex-col md:gap-4"}>
                    <p className="text-white-495 text-[12px] font-medium">PROJECTS</p>
                    <div className="w-full flex flex-col gap-3">
                        {projects?.map((value, index) => {
                            return (
                                <p key={index} className="text-[16px] font-medium truncate text-white-530 overflow-hidden">{value.title}</p>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className={isInView ? "flex items-center justify-center" : "flex items-center justify-center lg:justify-start gap-2 cursor-pointer"} onClick={()=>setOpen(true)}>
                <Logout/>
                <span className={isInView ? "hidden" : "hidden lg:flex md:text-logout md:font-semibold"}>Logout</span>
            </div>
            {open && 
                <LogoutModal 
                    isOpen={open} 
                    processing={processing} 
                    close={()=>setOpen(false)}
                    action={logout}
                />
            }
        </div>
    )
}

export default SideBar