import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AppDispatch } from '../../../Store/Store'
import { useDispatch } from 'react-redux'
import { useResetPasswordMutation } from '../../../Store/Slices/Auth/AuthApiSlice'
import { constructResponse } from '../../../Store/Slices/Response/ResponseSlice'
import { ProcessingRequest } from '../../UI/Loader'
import {ValidateResetPasswordForm as ValidateForm} from './ValidateForm'
import Card from './Card/Card'
import Input from '../../UI/Input'
import Button from '../../UI/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface FormType{
    password: string,
    confirmPassword: string
}
interface ErrorType{
    valid: boolean,
    message: string
}
interface FormErrorType{
    password: ErrorType,
    confirmPassword: ErrorType
    none: boolean
}

const ResetPassword:React.FC = () => {
    const [reset] = useResetPasswordMutation({})
    const dispatch:AppDispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    
    const [processing, setProcessing] = useState<boolean>(false)

    const [inputFields, setInputFields] = useState<FormType>({
        password: "",
        confirmPassword: ""
    })
    
    const [inputErrors, setInputErrors] = useState<FormErrorType>({
        password: {
            valid: false,
            message: ""
        },
        confirmPassword:{
            valid: false,
            message: ""
        },
        none: false
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;

        setInputFields(prev => {
            return {...prev, [name]:value}
        })
    }

    const handleSubmit = async(e:React.FormEvent) => {
        e.preventDefault();

        const errors:FormErrorType = ValidateForm(inputFields);
        setInputErrors(errors);

        if(errors.none){
            setProcessing(true)

            const payload = {
                newPassword: inputFields.confirmPassword
            }

            const {data, error} = await reset(payload);
            
            let newResponse = {
                valid: true,
                message: "",
                type:""
            }

            if(data){
                setProcessing(false);

                newResponse.message = data?.response?.message;
                newResponse.type = "success"

                navigate("/admin/login", {state: location , replace:true})
            }
            if(error){
                setProcessing(false)

                newResponse.message = "Error resetting password. Please retry.";
                newResponse.type = "failure"
            }

            dispatch(constructResponse(newResponse))
        }
    }

    return (
        <Card>
            <div className="w-full bg-white-600 rounded-lg relative">
                
                {processing && <ProcessingRequest style={"top-0 right-0"}/>}

                <h2 className="text-[32px] font-medium mb-8">Reset password</h2>
                <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                    <Input 
                        title="New password" 
                        type="password" 
                        id="password" 
                        name="password" 
                        placeholder="********"
                        value={inputFields.password} 
                        error={inputErrors.password}
                        onChange={handleChange}
                    />
                    <Input 
                        title="Confirm password" 
                        type="password" 
                        id="confirmPassword" 
                        name="confirmPassword" 
                        placeholder="********"
                        value={inputFields.confirmPassword} 
                        error={inputErrors.confirmPassword}
                        onChange={handleChange}
                    />
                    
                    <Button
                        type='submit'
                        style={"bg-orange w-full mx-auto mb-5 cursor-pointer text-white-600 block py-3 rounded-md"}
                        text="Reset Password"
                        action={()=>{}}
                    />
                </form>
                <Link to="/login" className="text-center text-sm flex items-center gap-2 justify-center"><ArrowBackIcon style={{fontSize: 14, color: 'black'}}/>Go back to login</Link>
            </div>
        </Card>
    )
}

export default ResetPassword;