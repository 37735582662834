import React from 'react'

const Add:React.FC<{color: string}> = ({color}) => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2013 18.4772C14.8036 18.4772 18.5346 14.7463 18.5346 10.1439C18.5346 5.54151 14.8036 1.81055 10.2013 1.81055C5.59888 1.81055 1.86792 5.54151 1.86792 10.1439C1.86792 14.7463 5.59888 18.4772 10.2013 18.4772Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.2012 6.81055V13.4772" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.86792 10.1445H13.5346" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Add