import { apiRoutes } from "../../../Config/ApiRoutes";
import { apiSlice } from "../../Api/apiSlice";

const MailApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        sendMailToCompany: builder.mutation({
            query: payload => ({
                url: apiRoutes.mail.SEND,
                method: "POST",
                body: {...payload}
            })
        })
    })
})

export const {
    useSendMailToCompanyMutation
} = MailApiSlice