import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import { AppDispatch, RootState } from '../../../../../../Store/Store';
import { Loader } from '../../../../../UI/Loader';
import { useGetAllCompletedProjectsQuery } from '../../../../../../Store/Slices/Project/ProjectApiSlice';
import { setFetchError, setFetching, setProjects } from '../../../../../../Store/Slices/Project/ProjectSlice';
import { getCategoryAndYear } from '../../../../../../Utils/Helper';
import EmptyState from '../../../../../UI/EmptyState';
import Retry from '../../../../../UI/Retry';

const MoreProjects:React.FC = () => {
    const {isFetching, projects, isFetchError, currentProject, isEmpty} = useSelector((state: RootState) => state.project);
    const dispatch:AppDispatch = useDispatch();
    const { data, error, refetch } = useGetAllCompletedProjectsQuery({});

    useEffect(()=>{
        dispatch(setFetching(true));
        dispatch(setFetchError(false));

        if(data){
            dispatch(setFetching(false));
            dispatch(setProjects([...data?.response]))
        }
        if(error){
            dispatch(setFetching(false))
            dispatch(setFetchError(true))
        }
    },[data, error, dispatch])

    const retryRequest = async() => {
        dispatch(setFetching(true))
        dispatch(setFetchError(false))
        
        const {data, error} = await refetch();
        if(data){
            dispatch(setFetching(false))
            dispatch(setProjects([...data?.response]))
        }
        if(error){
            dispatch(setFetching(false))
            dispatch(setFetchError(true))
        }
    }

    return (
        <div className="w-full px-[5%] py-12">
            <h1 className="text-[32px] leading-none md:text-[74px] font-medium">More projects</h1>
            <div className="relative min-h-[400px] w-full">
                {   
                    isFetching 
                    ? 
                        <Loader/> 
                    :
                    isFetchError ?
                        <Retry
                            action={retryRequest}
                        />
                    :
                    isEmpty ?
                        <EmptyState context={"projects"}/>
                    :
                        <ul className="w-full max-w-[1500px] flex items-start flex-wrap gap-[2%] mt-12 mx-auto">
                            {projects?.filter(project => project?.code !== currentProject?.code ).map((value, index) => {
                                return (
                                    <li key={index} className="w-full mt-8 lg:w-[49%]" >
                                        <Link to={`/projects/${value?.code}`} className="w-full" onClick={()=>dispatch(setFetching(true))}>
                                            <img src={value.images.find(image => image?.mainImage)?.url} className="w-full object-cover aspect-square" alt="Project-Image" />
                                            <p className="text-[20px] mt-6 w-full truncate overflow-hidden">{value?.title}</p>
                                            <small className="text-[12px] text-gray">{getCategoryAndYear(value?.category, value?.endDate)}</small>
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                }
            </div>
        </div>
    )
}

export default MoreProjects