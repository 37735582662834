import React, {useState} from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from '@mui/material';
import { useUploadFilesMutation } from '../../../../../Store/Slices/Project/ProjectApiSlice';
import { AppDispatch } from '../../../../../Store/Store';
import { useDispatch } from 'react-redux';
import { constructResponse } from '../../../../../Store/Slices/Response/ResponseSlice';
import upload from '../../../../../Assets/Icons/upload.svg'
import png from '../../../../../Assets/Icons/png.png'
import svg from '../../../../../Assets/Icons/svg.png'
import jpg from '../../../../../Assets/Icons/jpg.jpg'
import Status from './Status';

interface ImageUploadType{
    allowableImages: number,
    save: (imagesList: string[]) => void
}

interface FileStatusType{
    processing:boolean,
    img:string | undefined,
    name:string,
    size:string
}

const ImageUpload:React.FC<ImageUploadType> = ({save, allowableImages}) => {
    const  [uploadFn] = useUploadFilesMutation({});
    const [processing, setProcessing] = useState<boolean>(false);
    const [files, setFiles] = useState<FileStatusType[] | null>(null);
    const dispatch:AppDispatch = useDispatch();

    const getSize = (size: number) => {
        const value = size / (1024 * 1024)

        return `${Math.round(value)}MB`
    }

    const getImage = (extension: string) => {
        switch(extension){
            case "png":
                return png;
            case "jpg": 
                return jpg
            case "svg":
                return svg;
            default:
                return;
        }
    }

    const constructStatuses = (files: File[]) => {
        const statuses:FileStatusType[] = [];

        files.forEach(file => {
            const newStatus = {
                processing: processing,
                img: getImage(file.name.split(".")[1]),
                name: file.name,
                size: getSize(file.size)
            }

            statuses.push(newStatus)
        })
        return statuses;
    }

    const onDrop = async(acceptedFiles: File[]) => {
        let newResponse = {
            valid: true,
            message: "",
            type:""
        }

        if(files){
            newResponse.message = "Files have been selected."
            newResponse.type="failure"
            dispatch(constructResponse(newResponse))
            return;
        }
        else if(acceptedFiles.length > allowableImages){
            newResponse.message = `You can't select more than ${allowableImages} images.`
            newResponse.type="failure"
            dispatch(constructResponse(newResponse))
            return;
        }
        else{
            setProcessing(true)
            setFiles(constructStatuses(acceptedFiles))

            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append("files", file);
            });

            const {data, error} = await uploadFn(formData);
            if(data){
                setProcessing(false)
                
                const links = data?.response;
                const imageLinks:string[] = []

                for(let link of links){
                    imageLinks.push(link?.url)
                }
                
                save(imageLinks);
            }
            else{
                setProcessing(false)
                let newResponse = {
                    valid: true,
                    message: "Unable to upload images. Please retry.",
                    type:"failure"
                }

                setFiles(null)

                dispatch(constructResponse(newResponse))

            }
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'image/svg+xml': ['.svg'],
        },
        maxSize: 5 * 1024 * 1024,
    });

    const renderDropZone = () => (
        <Box
            sx={{
                border: '2px dashed #ddd',
                borderRadius: 2,
                padding: 2,
                textAlign: 'center',
                marginBottom: 2,
                marginTop: 2,
                cursor: 'pointer',
                '&:hover': {
                    borderColor: '#aaa',
                },
            }}
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            <div className="w-full h-[150px] flex flex-col justify-center gap-1">
                <img src={upload} className="w-10 mx-auto" alt="Upload"/>
                <p className="text-center text-[12px] text-white-325">Drag & drop or <span className="text-orange underline">choose file(s)</span> to upload.</p>
                <p className="text-[12px] text-white-325 text-center">Image format: JPG, PNG & SVG. Max 5.0MB</p>
            </div>
        </Box>
    );

    return (
        <div className="w-full" >
            <p className="text-sm text-white-495">IMAGES</p>
            
            {renderDropZone()}

            <ul className="w-full flex flex-col gap-3 mt-5">
                {files && files.map((value, index) => {
                    return (
                        <Status 
                            key={index}
                            processing={processing}
                            img={value.img}
                            name={value.name}
                            size={value.size}
                        />
                    )
                })}
            </ul>
        </div>
    );
};

export default ImageUpload;
