import React from 'react'
import stars from '../../../../Assets/Icons/stars.svg'
import PhoneIcon from '@mui/icons-material/Phone'; 
import overview from '../../../../Assets/Images/Landing/overview.png'
import OverviewWrapper from '../../../UI/OverviewWrapper';

const OverView:React.FC = () => {
    return (
        <OverviewWrapper
            style={"w-full bg-no-repeat bg-cover px-[5%] pt-5 pb-40 responsive-landing-overview-bg dark-section"}
            imageUrl={overview}
        >
            <div className="w-full max-w-[584.64px] pr-16 flex flex-col gap-8 mt-72 md:mt-36 mb-8 md:mb-20">
                <h4 className="text-2xl font-medium text-gradient">Leading in Engineering</h4>
                <h1 className="text-[48px] font-medium leading-none md:text-7xl text-white-600">Your Project, Our Expertise.</h1>
                <p className="text-[16px] font-normal text-white-500">
                    From design to installation and supervision, we deliver unmatched expertise and reliability for residential, commercial, and industrial projects.
                </p>
            </div>
            <a href="tel:+2348030776930" className=" hover:bg-hover transition-all duration-500 ease-out max-w-[330px] p-4 flex items-center gap-2 justify-center bg-orange rounded-lg text-white-600">
                <PhoneIcon fontSize="medium" style={{color: "white"}}/> 
                <span className="font-medium">Contact us: +234-803-077-6930</span>
            </a>
            <div className="w-full mt-7 md:mt-12">
                <img src={stars} className="w-30" alt="Rating"/>
                <p className="text-sm text-white-400 mt-2">Trusted by 100+ clients</p>
            </div>
        </OverviewWrapper>
    )
}

export default OverView