import { Routes as AppRoutes, Route, Navigate, useLocation } from 'react-router-dom';
import ReAuth from './ReAuth';
import LandingPage from '../Components/Main/LandingPage/LandingPage';
import Services from '../Components/Main/Services/Services';
import Projects from '../Components/Main/Projects/Projects';
import Contact from '../Components/Main/Contact/Contact';
import Project from '../Components/Main/Projects/Sections/Projects/Project/Project';
import Register from '../Components/Admin/Auth/Register';
import Login from '../Components/Admin/Auth/Login';
import VerifyEmail from '../Components/Admin/Auth/VerifyEmail';
import VerifyOtp from '../Components/Admin/Auth/VerifyOtp';
import ResetPassword from '../Components/Admin/Auth/ResetPassword';
import AboutUs from '../Components/Main/AboutUs/AboutUs';
import Home from '../Components/Admin/Management/Home/Home';
import CurrentProject from '../Components/Admin/Management/Home/Sections/Projects/CurrentProject/CurrentProject';
import ProjectUpsert from '../Components/Admin/Management/Projects/ProjectUpsert';

export const AdminRoutes:React.FC = () => {
    const location = useLocation()
    return (
        <AppRoutes>
            <Route path="/admin">
                <Route index element={<Navigate to="/admin/login" state={{from: location}} replace/>}/>
                <Route path="register" element={<Register/>}/>
                <Route path="login" element={<Login/>}/>
                <Route path="verify-email" element={<VerifyEmail/>}/>
                <Route path="verify-otp" element={<VerifyOtp/>}/>
                <Route path="reset-password" element={<ResetPassword/>}/>

                {/* ADMIN */}
                <Route element={<ReAuth/>}>
                    <Route path="/admin/home">
                        <Route index element={<Home/>}/>
                        <Route path="view/:id" element={<CurrentProject/>}/>
                    </Route>
                    <Route path="/admin/projects">
                        <Route index element={<ProjectUpsert isUpdate={false}/>}/>
                        <Route path=":id" element={<ProjectUpsert isUpdate={true}/>}/>
                    </Route>
                </Route>

                <Route path="*" element ={<Navigate to="/admin/login" state={{from: location}} replace/>}/>
            </Route>
        </AppRoutes>
    )
}

export const MainRoutes:React.FC = () => {
    const location = useLocation()
    return(
        <AppRoutes>
            <Route path="/" element={<LandingPage/>}/>
            <Route path="/about" element={<AboutUs/>}/>
            <Route path="/services" element={<Services/>}/>
            <Route path="/projects">
                <Route index element={<Projects/>}/>
                <Route path=":id" element={<Project/>}/>
            </Route>
            <Route path="/contact" element={<Contact/>}/>

            <Route path="*" element ={<Navigate to="/" state={{from: location}} replace/>}/>
        </AppRoutes>
        
    )
}