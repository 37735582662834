import React, {useState, useEffect, ReactNode} from 'react'
import SideBar from '../../UI/SideBar/SideBar'
import {useLocation} from 'react-router-dom'

const Card:React.FC<{children: ReactNode}> = ({children}) => {
  const [isInView, setIsInView] = useState<boolean>(false);
  const location = useLocation()

  useEffect(()=>{
    if(location.pathname.includes("view")) setIsInView(true)
    else setIsInView(false)
},[location.pathname])

  return (
    <div className={isInView ? "w-full h-screen grid grid-cols-[0.5fr_4fr]" : "w-full h-screen grid grid-cols-[0.5fr_4fr] lg:grid-cols-[1fr_4fr]"}>
      <SideBar/>
      <div className="w-full h-full overflow-y-scroll py-6 px-[3%]">
        {children}
      </div>
    </div>
  )
}

export default Card