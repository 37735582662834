const isEmpty = (string) => {
    return string?.length === 0;
}

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const ValidateRegistrationForm = (data) => {
    let error = {
        valid: true,
        message: "" 
    }
    
    let errors = {}
    const {firstName, lastName, email, password} = data;

    if(isEmpty(firstName)){
        error.message = "First name cannot be empty."
        errors.firstName = {...error}
    }
    if(isEmpty(lastName)){
        error.message = "Last name cannot be empty."
        errors.lastName = {...error}
    }
    if(!emailRegex.test(email)){
        error.message = "Enter a valid email."
        errors.email = {...error}
    }
    if(password?.length < 8){
        error.message = "Password too short."
        errors.password = {...error}
    }

    if(Object.values(data).every(input => !isEmpty(input)) && emailRegex.test(email) && password?.length > 8) errors.none = true

    return errors;
}

export const ValidateLoginForm = (data) => {
    let error = {
        valid: true,
        message: "" 
    }
    
    let errors = {}
    const {email, password} = data;

    if(!emailRegex.test(email)){
        error.message = "Enter a valid email."
        errors.email = {...error}
    }
    if(password?.length < 8){
        error.message = "Password too short."
        errors.password = {...error}
    }

    if(Object.values(data).every(input => !isEmpty(input)) && emailRegex.test(email) && password?.length > 8) errors.none = true

    return errors;
}

export const ValidateEmailVerificationForm = (data) => {
    let error = {
        valid: true,
        message: "" 
    }
    
    let errors = {}
    const {email} = data;

    if(!emailRegex.test(email)){
        error.message = "Enter a valid email."
        errors.email = {...error}
    }
    else{
        errors.none = true;
    }

    return errors;
}
export const ValidateOtpVerificationForm = (data) => {
    let error = {
        valid: true,
        message: "" 
    }
    
    let errors = {}
    if(data?.length < 4){
        error.message = "OTP cannot be less than 4 characters."
        errors.otp = {...error}
    }
    else{
        errors.none = true;
    }

    return errors;
}
export const ValidateResetPasswordForm = (data) => {
    let error = {
        valid: true,
        message: "" 
    }
    
    let errors = {}
    const {password, confirmPassword} = data;

    if(password?.length < 8){
        error.message = "Password too short."
        errors.password = {...error}
    }
    if(confirmPassword?.length < 8){
        error.message = "Password too short."
        errors.password = {...error}
    }
    if(password !== confirmPassword){
        error.message = "Password too short."
        errors.password = {...error}
        errors.confirmPassword = {...error}
    }

    if(password.length > 8 && (password === confirmPassword)){
        errors.none = true;
    }

    return errors;
}