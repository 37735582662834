import React, { useState } from 'react'
import { Visibility as Eye, Edit, Delete } from '@mui/icons-material'
interface OptionsType{
    viewFn: () => void,
    editFn: () => void
    openDeleteModal: () => void,
    id: string
}

const OptionsList:React.FC<OptionsType> = ({id, viewFn, editFn, openDeleteModal}) => {
    const [isDelete, setIsDelete] = useState<boolean>(false)

    return (
        <div className="w-[230px] absolute top-3 right-3 bg-white-600 rounded-lg shadow-xl z-10 border-white-400 border-[1px] py-3 px-2">
            <div className="flex items-center gap-3 cursor-pointer p-2"  onClick={viewFn}>
                <Eye style={{color: 'rgba(34, 34, 34, 0.6)'}}/>
                <p className="text-[16px] text-black-475">View</p>
            </div>
            <div className="flex items-center gap-3 cursor-pointer p-2" onClick={editFn}>
                <Edit style={{color: 'rgba(34, 34, 34, 0.6)'}}/>
                <p className="text-[16px] text-black-475">Edit</p>
            </div>
            <div className="w-full border-t-[1px] border-t-white-400 px-2 py-3">
                <div className="w-full flex items-center gap-3 cursor-pointer"  onClick={()=>setIsDelete(true)}>
                    <Delete style={{color: 'rgba(34, 34, 34, 0.6)'}}/>
                    <p className="text-[16px] text-black-475">{isDelete ? "Are you sure?" : "Delete"}</p>
                </div>
                {isDelete && 
                    <div className="w-full transition-all ease-out duration-500 mt-4 flex items-center gap-2 ">
                        <button type="button" className="text-white-495 rounded-md border-[1px] text-[13px] border-white-495 w-full p-2" onClick={(e)=>{
                            e.stopPropagation()
                            setIsDelete(false)
                        }}>No</button>
                        <button type="button" className="text-white-600 rounded-md border-[1px] text-[13px] border-red-500 w-full bg-red-500 p-2" onClick={openDeleteModal}>Delete</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default OptionsList