import React, { useState } from 'react'
import { ProcessingRequest } from '../../../../../UI/Loader'
import { useSendMailToCompanyMutation } from '../../../../../../Store/Slices/Mail/MailApiSlice'
import { constructResponse } from '../../../../../../Store/Slices/Response/ResponseSlice'
import { AppDispatch } from '../../../../../../Store/Store'
import { useDispatch } from 'react-redux'
import Input from '../../../../../UI/Input'
import Button from '../../../../../UI/Button'
import ValidateForm from './Validation'

interface FormType{
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    message: string
}
interface ErrorType{
    valid: boolean,
    message: string
}
interface FormErrorType{
    firstName: ErrorType,
    lastName: ErrorType,
    email: ErrorType,
    phone: ErrorType,
    message: ErrorType,
    none: boolean
}


const Form:React.FC = () => {
    const [send] = useSendMailToCompanyMutation({});
    const dispatch:AppDispatch = useDispatch()

    const [processing, setProcessing] = useState<boolean>(false)

    const [inputFields, setInputFields] = useState<FormType>({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: ""
    })
    
    const [inputErrors, setInputErrors] = useState<FormErrorType>({
        firstName: {
            valid: false,
            message: ""
        },
        lastName: {
            valid: false,
            message: ""
        },
        email: {
            valid: false,
            message: ""
        },
        phone: {
            valid: false,
            message: ""
        },
        message: {
            valid: false,
            message: ""
        },
        none: false
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;

        setInputFields(prev => {
            return {...prev, [name]:value}
        })
    }

    const handleSubmit = async(e:React.FormEvent) => {
        e.preventDefault();

        const errors:FormErrorType = ValidateForm(inputFields);
        setInputErrors(errors);

        if(errors.none){
            const currentWidth = window.innerWidth;
            
            window.scrollTo({
                top: currentWidth < 1024 ? 600 : 0,
                behavior: "smooth"
            })

            setProcessing(true)

            const {data, error} = await send(inputFields)

            let newResponse = {
                valid: true,
                message: "",
                type:""
            }

            if(data){
                setProcessing(false)
                setInputFields({
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    message: ""
                })
                newResponse.message = data?.response.message
                newResponse.type = "success"
            }
            if(error){
                setProcessing(false)

                newResponse.message = "Failed to send mail."
                newResponse.type = "failure"
            }

            dispatch(constructResponse(newResponse))
        }
    }

    return (
        <div className="w-full bg-white-600 rounded-lg px-[5%] py-12 md:py-8 lg:px[3%] relative" id="form">

            {processing && <ProcessingRequest style={"top-8 right-[3%]"}/>}
            
            <h2 className="text-[32px] font-semibold mb-8">Contact form</h2>
            <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                <div className="flex flex-col lg:flex-row lg:items-center gap-4 justify-between">
                    <Input 
                        title="First name" 
                        type="text" 
                        id="firstName" 
                        name="firstName" 
                        placeholder="First name"
                        value={inputFields.firstName} 
                        error={inputErrors.firstName}
                        onChange={handleChange}
                    />
                    <Input 
                        title="Last-Name" 
                        type="text" 
                        id="lastName" 
                        name="lastName" 
                        placeholder="Last name"
                        error={inputErrors.lastName}
                        value={inputFields.lastName} 
                        onChange={handleChange}
                    />
                </div>
                <Input 
                    title="Email" 
                    type="text" 
                    id="email" 
                    name="email" 
                    placeholder="example@gmail.com"
                    value={inputFields.email} 
                    error={inputErrors.email}
                    onChange={handleChange}
                />
                <Input 
                    title="Phone" 
                    type="text" 
                    id="phone" 
                    name="phone" 
                    placeholder="Phone number"
                    value={inputFields.phone} 
                    error={inputErrors.phone}
                    onChange={handleChange}
                />
                <Input 
                    title="Want to tell us anything?" 
                    name="message" 
                    id="message" 
                    type="message"
                    placeholder="Comments"
                    value={inputFields.message} 
                    error={inputErrors.message}
                    onChange={handleChange}
                />
                <Button
                    type='submit'
                    style={"bg-orange w-full mx-auto cursor-pointer text-white-600 block py-3 rounded-md"}
                    text="Submit"
                    action={()=>{}}
                />
            </form>
        </div>
    )
}

export default Form