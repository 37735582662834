import React from 'react'
import mission from '../../../../Assets/Images/About/mission-statement.png'

const MissionStatement:React.FC = () => {
  return (
    <section className="w-full mx-auto px-[5%] py-16 md:flex gap-12 items-center light-section" data-scroll-section>
      <img src={mission} alt="Engineer" className="w-full object-cover md:max-w-[459px] md:h-[505px]" />
      <div className="mb-6 md:mb-0">
          <h1 className="text-[32px] font-medium md:text-[74px] mt-14 md:mt-0 leading-none">Mission <span className="md:block lg:inline">Statement</span></h1>
          <p className="text-sm max-w-[512.83px] mt-4 md:mt-14">
            Our mission is to develop cost effective solution that will meet the unique present and future requirement of our clients. To accomplish this, we investigate all reasonable options where appropriate; seek new technologies and design customized engineering solutions.
          </p>
      </div>
  </section>
  )
}

export default MissionStatement