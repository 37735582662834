const api = "/api/v1"
const authBaseRoute = "auth"
const mailBaseRoute = "mails"
const projectBaseRoute = "projects"
const fileBaseRoute = "files"

const mailRoutes = {
    SEND:  `${api}/${mailBaseRoute}/send`
}

const projectRoutes = {
    CREATE: `${api}/${projectBaseRoute}/create`,
    GET: `${api}/${projectBaseRoute}`,
    EDIT: `${api}/${projectBaseRoute}/`,
    DELETE: `${api}/${projectBaseRoute}/delete/`
}

const authRoutes = {
    REGISTER: `${api}/${authBaseRoute}/register`,
    LOGIN: `${api}/${authBaseRoute}/login`,
    EMAIL: `${api}/${authBaseRoute}/verify-email`,
    OTP: `${api}/${authBaseRoute}/verify-otp`,
    RESET: `${api}/${authBaseRoute}/reset-password`,
    REFRESH: `${api}/${authBaseRoute}/refresh`,
    LOGOUT: `${api}/${authBaseRoute}/logout`
}

const fileRoutes = {
    UPLOAD: `${api}/${fileBaseRoute}/images/upload`
}

export const apiRoutes = {
    auth: authRoutes,
    mail: mailRoutes,
    project: projectRoutes,
    file: fileRoutes
}