const isEmpty = (string) => {
    return string?.length === 0;
}

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const ValidateForm = (data) => {
    const { firstName, lastName, email, phone, message } = data;

    let error = {
        valid: false,
        message: "" 
    }

    let errors = {}

    if(isEmpty(firstName)){
        error.valid = true
        errors.firstName = {...error}
    }
    if(isEmpty(lastName)){
        error.valid = true
        errors.lastName = {...error}
    }
    if(isEmpty(phone)){
        error.valid = true
        errors.phone = {...error}
    }
    if(isEmpty(message)){
        error.valid = true
        errors.message = {...error}
    }

    if(!emailRegex.test(email)){
        error.valid = true
        error.message = "Please enter a valid email"
        errors.email = {...error}
    }

    if(Object.values(data).every(field => !isEmpty(field)) && emailRegex.test(email)) errors.none = true;

    return errors;
}

export default ValidateForm;